import FormControlLabel from '@mui/material/FormControlLabel';
import { FormControl, FormLabel, Radio, RadioGroup } from '@mui/material';

type RadiogroupComponentProps = {
    value: string;
    handleChange: Function;
}

function RadiogroupComponent({ value, handleChange }: RadiogroupComponentProps) {
    return (
        <FormControl>
            <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={value}
                onChange={(event) => handleChange(event.target.value)}>
                <FormControlLabel value="both" control={<Radio />} label="Both" />
                <FormControlLabel value="intee" control={<Radio />} label="INT EE" />
                <FormControlLabel value="intFra" control={<Radio />} label="INT FRA" />
            </RadioGroup>
        </FormControl>
    );
};

export default RadiogroupComponent;