import { useMsal } from "@azure/msal-react";
import { Button, Card, CardContent, Typography } from "@mui/material";
import { themeINTEE } from "../../utils/theme";

function Login() {
  const { instance } = useMsal();

  const handleLogin = async () => {
    try {
      await instance.loginRedirect(); // Use loginRedirect instead of loginPopup
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
      <Card sx={{ width: "100%", maxWidth: 400, padding: themeINTEE.spacing(2) }}>
        <CardContent sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Typography variant="h5" gutterBottom>
            Welcome to the Integration internal Databases<br/><br/> Please log in with your Microsoft account
          </Typography>
          <Button variant="contained" color="primary" onClick={handleLogin}>
            Log In
          </Button>
        </CardContent>
      </Card>
    </div>
  );
}

export default Login;
