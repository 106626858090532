import { Grid} from "@mui/material";
import { useEffect, useState } from "react";
import { postData } from "../../services/base.service";
import ExpertForm from "../Forms/ExpertForm";
import { set } from "date-fns";

type AddExpertProps = {
    setLoading: Function,
    accessToken: string
    expertID: number,
    setFilters: Function,
    setDetailsDrawerOpen: Function
  }
  
  function AddExpert({ setLoading, accessToken, expertID, setFilters, setDetailsDrawerOpen }: AddExpertProps) {
    const [expertData, setExpertData] = useState(Object);

    const fetchExpertData = async () => {
        await postData('experts/detail', { expertID: expertID }).then((response) => {
            setLoading(true);
            setExpertData(response);
            setLoading(false);
        })
        .catch((error) => {
            console.log(error);
            setLoading(false);
        });
    };

    useEffect(() => {
      setLoading(true);
      fetchExpertData();
      setLoading(false);
    },[expertID]);

    return (
        expertData && expertData.expertsDetails ? ( // Check if expertData and expertData.expertsDetails are not null before rendering
        <Grid>
          <ExpertForm expertData={expertData} type='add' setLoading={setLoading} accessToken={accessToken}
          setFilters={setFilters} setDetailsDrawerOpen={setDetailsDrawerOpen}/>
        </Grid>
      ) : null // Render nothing if expertData or expertData.expertsDetails is null
    )
  }
  
  export default AddExpert;



