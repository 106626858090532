import { Print, Delete, Filter, Deselect, ArrowDropDown, SelectAll } from "@mui/icons-material";
import { Badge, Dialog, Grid, Popover, Button, List, ListItem, Typography, IconButton, Grow, Divider } from "@mui/material";
import PrintTemplates from "../Popups/PrintTemplates";
import { useState, useRef, useEffect } from "react";
import { ReferencesFilterType } from "../../types/dataTypes";



type ReferenceSelectionShoppingCartProps = {
    selectedReferences: string[];
    setSelectedReferences: Function;
    setFilters: Function;
    setReload: Function;
}

function ReferenceSelectionShoppingCart({ selectedReferences, setSelectedReferences, setFilters, setReload }: ReferenceSelectionShoppingCartProps) {
    const [printDialogOpen, setPrintDialogOpen] = useState(false);
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [showSelected, setShowSelected] = useState(false);

    const handlePrintTemplatesDialogOpen = () => {
        if (selectedReferences.length === 0) {
            alert("Please select minimum 1 row to print");
            return;
        }
        setPrintDialogOpen(true);
    };

    const handlePrintTemplatesDialogClose = () => {
        setPrintDialogOpen(false);
    };

    const handlePopoverOpen = (event: any) => {
        if (selectedReferences.length > 0) {
            setAnchorEl(event.currentTarget);
            setPopoverOpen(true);
        } else {
            // Showing an alert as a simple solution. Consider using a more user-friendly notification system in a real application.
            alert("Please select a reference first.");
        }
    };

    const handlePopoverClose = () => {
        setPopoverOpen(false);
    };

    const handleDelete = (referenceToDelete: string) => {
        setSelectedReferences(selectedReferences.filter(reference => reference !== referenceToDelete));
    };

    const handlePrintDeselectionAll = () => {
        setSelectedReferences([]);
        setReload(true);
        setPopoverOpen(false);
    };

    const handleFilterPrintSelection = () => {
        if(showSelected == false){
            setFilters((prevFilters: ReferencesFilterType) => ({
                ...prevFilters,
                printSelectionList: selectedReferences,
            }));
            setShowSelected(true);
        }
        else{
            setFilters((prevFilters: ReferencesFilterType) => ({
                ...prevFilters,
                printSelectionList: [],
            }));
            setShowSelected(false);
        }

    };


    return (
        <Grid container>
            <Grid item xs={12}>
                <IconButton color="primary" onClick={handlePopoverOpen}>
                    <Badge badgeContent={selectedReferences.length} color="primary"   anchorOrigin={{vertical: 'top', horizontal: 'left'}}>
                        <Print color="action" />
                    </Badge>
                    <Typography sx={{paddingLeft:1}}>Print Selection</Typography>
                    <ArrowDropDown color="action" />
                </IconButton>
                <Popover
                    open={popoverOpen}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <List>
                        {selectedReferences.map((reference, index) => (
                            <ListItem key={index}>
                                <Grid container justifyContent='space-between' alignItems='center'>
                                    <Grid item xs={10}>
                                        <Typography>{reference}</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <IconButton onClick={() => handleDelete(reference)}>
                                            <Delete />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </ListItem>
                        ))}
                        <Divider style={{ color: 'lightgrey' }} />

                        <ListItem>
                            <Button startIcon={showSelected == true ? <SelectAll/> : <Filter/>} variant="text" color="primary" onClick={handleFilterPrintSelection}>
                                {showSelected == true ? "Show all references" : "Show selected references"}
                            </Button>
                        </ListItem>
                        <ListItem sx={{paddingTop:2}}>
                            <Button startIcon={<Deselect />} variant="text" color="primary" onClick={handlePrintDeselectionAll}>
                                Deselect all references
                            </Button>
                        </ListItem>
                        <ListItem>
                            <Button variant="contained" color="primary" onClick={handlePrintTemplatesDialogOpen}>
                                Print Current Selection
                            </Button>
                        </ListItem>

                    </List>
                </Popover>
            </Grid>
            <Dialog open={printDialogOpen} onClose={handlePrintTemplatesDialogClose}>
                <PrintTemplates project_number_list={selectedReferences.sort()} handleClose={handlePrintTemplatesDialogClose} />
            </Dialog>
        </Grid>
    );
}

export default ReferenceSelectionShoppingCart;