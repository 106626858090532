import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { postData } from "../../services/base.service";
import ExpertForm from "../Forms/ExpertForm";
import CustomDrawer from "../Shared/CustomDrawer";

type UpdateExpertProps = {
    expertID: number,
    setLoading: Function,
    accessToken: string,
    setFilters: Function,
    isDrawerOpen: boolean,
    setIsDrawerDetailsOpen: Function
  }
  
  function UpdateExpert({ setLoading, accessToken, expertID,  setFilters, isDrawerOpen, setIsDrawerDetailsOpen }: 
    UpdateExpertProps) {
    const [expertData, setExpertData] = useState(Object);

    const fetchExpertData = async () => {
        postData('experts/detail', { expertID: expertID }).then((response) => {
            setExpertData(response);
        })
        .catch((error) => {
            console.log(error);
            setLoading(false);
        });
    };

    useEffect(() => {
        setLoading(true);
        fetchExpertData();
        setLoading(false);
    },[expertID]);

    return (
        expertData && expertData.expertsDetails ? ( // Check if expertData and expertData.expertsDetails are not null before rendering
          <CustomDrawer open={isDrawerOpen} toggleDrawer={setIsDrawerDetailsOpen} vw={40} title={'Experts Details'} orientation="right">
            <Grid>
              <ExpertForm type='update' setLoading={setLoading} accessToken={accessToken} expertData={expertData}
              setFilters={setFilters} setDetailsDrawerOpen={setIsDrawerDetailsOpen}/>
            </Grid>
          </CustomDrawer>
      ) : null // Render nothing if expertData or expertData.expertsDetails is null
    )
  }
  
  export default UpdateExpert;



