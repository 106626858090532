import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { getData } from "../../services/base.service";
import { ReferenceAvailableOptionsType, ReferenceDetailType } from "../../types/dataTypes";
import ReferenceForm from "../Forms/ReferenceForm";
import { initialFormData } from "../../utils/consts";

type AddReferenceProps = {
  setLoading: Function,
  handleClose: Function,
  setReload: Function,
}

function AddReference({ setLoading, handleClose, setReload }: AddReferenceProps) {
  const [availableOptions, setAvailableOptions] = useState<ReferenceAvailableOptionsType>({ clients: [], countries: [], fieldsofServices: [], countries_homepage: []});
  const [selectedOptions, setselectedOptions] = useState<ReferenceAvailableOptionsType>({ clients: [], countries: [], fieldsofServices: [], countries_homepage: []});
  const [formData, setFormData] = useState<ReferenceDetailType[]>([initialFormData]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        // fetch project data
        const response = await getData(`references/availableOptions`);
        setAvailableOptions(response);
        setLoading(false);
      }
      catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  return (
    availableOptions ? ( // Check if availableOptions is not null before rendering
      <Grid>
        <ReferenceForm setLoading={setLoading} availableOptions={availableOptions} formData={formData} type='add'
        handleClose ={handleClose} selectedOptions={selectedOptions} setSelectedOptions={setselectedOptions} setReload={setReload}/>
      </Grid>
    ) : null // Render nothing if expertData or expertData.expertsDetails is null
  )
}

export default AddReference;



