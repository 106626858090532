import { PublicClientApplication } from '@azure/msal-browser';

export const msalConfig = {
  auth: {
    //read from env
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: process.env.REACT_APP_AUTHORITY,
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

export const msalInstance = new PublicClientApplication(msalConfig as any);

export async function getAccessTokenSilent() {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw new Error('No active account');
  }
  const response = await msalInstance.acquireTokenSilent({
    account: account,
    scopes: [process.env.REACT_APP_API_SCOPE as string],
  });
  return response.accessToken;
}