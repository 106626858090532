import { useEffect, useState } from "react";
import { Card, CardContent, Typography, Button, Divider } from "@mui/material";
import Grid from "@mui/material/Grid";
import AutocompleteComponent from "../Shared/AutocompleteComponent";
import CountUpComponent from "../Shared/CountUpComponent";
import {
  getDataPagination,
  postDataPagination,
} from "../../services/base.service";
import FreeTextComponent from "../Shared/FreeTextComonent";
import { PAGE_LIMIT } from "../../utils/consts";
import {
  ExpertDataArrayType,
  SetExpertDataArrayType,
  SetPageType,
  PageType,
  SetLoadingType,
  FiltersType,
} from "../../types/dataTypes";
import { ArrowForward, Refresh } from '@mui/icons-material';
import ControlledTreeView from "../Shared/ControlledTreeView";
import CustomDrawer from "../Shared/CustomDrawer";


type ExpertsFilterProps = {
  data: ExpertDataArrayType;
  setData: SetExpertDataArrayType;
  setFilteredData: SetExpertDataArrayType;
  setPageTotal: SetPageType;
  page: PageType;
  setLoading: SetLoadingType;
  filters: FiltersType;
  setFilters: Function;
  sortConfig: { key: string, direction: string };
  isDrawerOpen: boolean,
  setIsDrawerOpen: Function
};

// Main component for filtering experts
function ExpertsFilters({
  data,
  setData,
  setFilteredData,
  setPageTotal,
  page,
  setLoading,
  filters,
  setFilters,
  sortConfig,
  isDrawerOpen,
  setIsDrawerOpen
}: ExpertsFilterProps) {

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [availableFilters, setAvailableFilters] = useState(filters);
  const [fullTextSearchValue, setFullTextSearchValue] = useState("");
  const [langSelectectionisAnd, setlangSelectectionisAnd] = useState(true);
  const [regionSelectectionisAnd, setregionSelectectionisAnd] = useState(true);
  const [nameSelectectionisAnd, setnameSelectectionisAnd] = useState(true);
  const [donorSelectectionisAnd, setdonorSelectectionisAnd] = useState(true);
  const [keywordsSelectectionisAnd, setkeywordsSelectectionisAnd] = useState(true);
  const [nationalitiesSelectectionisAnd, setnationalitiesSelectectionisAnd] = useState(true);



  // State to track number of experts (assuming it's derived from filtered data)
  const [numberOfExperts, setNumberOfExperts] = useState(data.length);

  // Handler to fetch filtered data or initial data based on the current filters
  const fetchData = async () => {
    setLoading(true);

    // Check if any filters are applied
    const areFiltersApplied = Object.values(filters).some(
      (filter) => filter.length > 0
    );

    if (areFiltersApplied) {
      // Prepare filter payload for API
      const filtersApi = Object.keys(filters).reduce(
        (acc, key) => ({
          ...acc,
          [key]: { isFiltered: filters[key].length > 0, values: filters[key],
            isAnd: 
            key === 'languages' ? langSelectectionisAnd : 
            key === 'regions' ? regionSelectectionisAnd : 
            key === 'names' ? nameSelectectionisAnd : 
            key === 'donors' ? donorSelectectionisAnd : 
            key === 'keywords' ? keywordsSelectectionisAnd : 
            key === 'nationalities' ? nationalitiesSelectectionisAnd : false
           },
        }),
        { sortConfig: sortConfig }
      );

      const result = await postDataPagination(
        "experts/filterdata",
        page,
        PAGE_LIMIT,
        { filtersApi }
      );
      // Update state based on API response
      setFilteredData(result.filteredExpertData);
      setAvailableFilters(result.availableFilters);
      setPageTotal(result.total);
      setNumberOfExperts(result.total);
    } else {
      // Fetch initial data if no filters are applied
      const result = await postDataPagination(
        "experts/expertsTable",
        page,
        PAGE_LIMIT,
        { sortConfig }
      );
      setData(result.data); // this sets the overall dataset
      setFilteredData(result.data); // Sets the currently visible/filtered dataset
      setPageTotal(result.total);
      setAvailableFilters(result.availableFilters);
      setNumberOfExperts(result.total);
    }

    setLoading(false);
  };

  // Fetch data on component mount and when filters change
  useEffect(() => {
    fetchData();
  }, [filters, page, sortConfig]);

  // Handlers for filter changes - accumulate changes in `filters` state
  const handleFilterChange =
    (filterName: string) => (event: any, newValue: any) => {
      setFilters((prevFilters: any) => ({ ...prevFilters, [filterName]: newValue }));
    };

  // Reset filters to initial state and fetch initial data
  const resetFilters = () => {
    setFullTextSearchValue(""); // Clear the full text search input value
    setFilters({
      names: [],
      languages: [],
      regions: [],
      donors: [],
      keywords: [],
      fulltext: [],
      nationalities: [],
    });
  };

  const handleFullTextSearch = (searchValue: any) => {
    setFilters((prevFilters: any) => ({ ...prevFilters, fulltext: searchValue }));
  };

  return (
    <div>
      <Grid container spacing={2} justifyContent="space-between">
        {/* Main column */}
        <Grid item xs={12} md={8}>
          <FreeTextComponent
            onSearch={handleFullTextSearch}
            label="Full Text Search"
            inputValue={fullTextSearchValue}
            setInputValue={setFullTextSearchValue}
            setIsDialogOpen={setIsDialogOpen}
            dialogOpen={isDialogOpen}
          />
          {/* Nested Grid for AutocompleteComponents */}
          <Grid container spacing={3} paddingTop={2}>
            <Grid item xs={12} md={6}>
              <Grid>
                <AutocompleteComponent
                  options={availableFilters.languages}
                  value={filters.languages}
                  handleChange={handleFilterChange("languages")}
                  isMulti={true}
                  label="Search by Language experience"
                  // radioState={langSelectectionisAnd}
                  // setRadioState={setlangSelectectionisAnd}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid>
                <AutocompleteComponent
                  options={availableFilters.regions}
                  value={filters.regions}
                  handleChange={handleFilterChange("regions")}
                  isMulti={true}
                  label="Search by regional experience"
                  // radioState={regionSelectectionisAnd}
                  // setRadioState={setregionSelectectionisAnd}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <CountUpComponent resetFilters={resetFilters} end={numberOfExperts} />
        </Grid>
      </Grid>
      <CustomDrawer open={isDrawerOpen} toggleDrawer={setIsDrawerOpen} vw={20} title={'Advanced Filter'} orientation="left">
        <Grid paddingBottom={3}>
          <AutocompleteComponent
            options={availableFilters.names}
            value={filters.names}
            handleChange={handleFilterChange("names")}
            isMulti={true}
            label="Search by Name"
            // radioState={nameSelectectionisAnd}
            // setRadioState={setnameSelectectionisAnd}
          />
        </Grid>
        <Grid paddingBottom={3}>
          <AutocompleteComponent
            options={availableFilters.donors}
            value={filters.donors}
            handleChange={handleFilterChange("donors")}
            isMulti={true}
            label="Search by Donor worked with"
            // radioState={donorSelectectionisAnd}
            // setRadioState={setdonorSelectectionisAnd}
          />
        </Grid>
        <Grid>
          <AutocompleteComponent
            options={availableFilters.nationalities}
            value={filters.nationalities}
            handleChange={handleFilterChange("nationalities")}
            isMulti={true}
            label="Search by Experts Nationality"
            // radioState={donorSelectectionisAnd}
            // setRadioState={setdonorSelectectionisAnd}
          />
        </Grid>
        <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
        <ControlledTreeView
          data={availableFilters.keywords}
          handleChange={handleFilterChange("keywords")}
          radioState={keywordsSelectectionisAnd}
          setRadioState={setkeywordsSelectectionisAnd}
          enabled={true}
        />
      </CustomDrawer>
    </div>
  );
}

export default ExpertsFilters;
