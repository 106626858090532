import React from 'react';
import { Button, Box, Typography, Card, IconButton, Icon, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Login from '../components/Login/Login'
import { themeINTEE } from '../utils/theme';
import backgroundImage from '../assets/background.jpg';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ListAltIcon from '@mui/icons-material/ListAlt';

type LandingPageProps = {
  isAuthenticated: boolean,
  userRole: string
};

function LandingPage({ isAuthenticated, userRole }: LandingPageProps) {
  const navigate = useNavigate();


  return (
    <Box
      sx={{
        height: '100vh',
        width: '100%',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      {isAuthenticated ? (
        <Card sx={{ padding: themeINTEE.spacing(2) }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Typography variant="h1" gutterBottom>
                Welcome
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <IconButton
                color="primary"
                sx={{ margin: 1, fontSize: 20 }} // Increase the size of the icon
                onClick={() => {
                  if (userRole === 'INTFra' || userRole === 'AdminINTFra') {
                    window.alert('Access to Experts View is not enabled for INTFra group.');
                  } else {
                    navigate('/experts');
                  }
                }}
              >
                <PeopleAltIcon style={{ fontSize: '30' }} />
                Expert Database
              </IconButton>
            </Grid>
            <Grid item xs={12} md={6}>
              <IconButton
                color='primary'
                sx={{ margin: 1, fontSize: 20 }} // Increase the size of the icon
                onClick={() => navigate('/references')}
              >
                <ListAltIcon style={{ fontSize: '30' }} />
                Reference Database
              </IconButton>
            </Grid>
          </Grid>
        </Card>
      ) : (
        <Login />
      )}
    </Box>
  );
};

export default LandingPage;