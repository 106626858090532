import { useEffect, useState } from "react";
import { Card, CardContent, Typography, Button, Grid, Input, Divider} from "@mui/material";
import AutocompleteComponent from "../Shared/AutocompleteComponent";
import RadiogroupComponent from "../Shared/RadiogroupComponent";
import CountUpComponent from "../Shared/CountUpComponent";
import { ReferencesTableType, ReferencesFilterType, ReferencesMultiFiltersType } from "../../types/dataTypes";
import dayjs from 'dayjs';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FreeTextComponent from "../Shared/FreeTextComonent";
import { postData } from "../../services/base.service";
import { initialFilters } from "../../utils/consts";
import CustomDrawer from "../Shared/CustomDrawer";

import {ArrowForward, Refresh} from "@mui/icons-material";


type ReferenceFiltersProps = {
  data: ReferencesTableType[];
  setFilteredData: Function;
  setLoading: Function;
  setReload: Function;
  filters: ReferencesFilterType;
  setFilters: Function;
  isDrawerOpen: boolean;
  setIsDrawerOpen: Function;
}

function ReferenceFilters({ data, setFilteredData, setLoading, setReload, filters, setFilters, isDrawerOpen, setIsDrawerOpen}: ReferenceFiltersProps) {
  const [numberOfReferences, setNumberOfReferences] = useState(data.length);
  const [countrySelectectionisAnd, setCountrySelectionisAnd] = useState(true);
  const [fosSelectectionisAnd, setfosSelectectionisAnd] = useState(true);
  const [refNoTitleSelectectionisAnd, setrefNoTitleSelectectionisAnd] = useState(true);
  const [fullTextSearchValue, setFullTextSearchValue] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // State for available options for each filter
  const [availableOptions, setAvailableOptions] = useState<ReferencesMultiFiltersType>({
    countries: [],
    fieldsOfService: [],
    refNumTitles: [],
  });

  const resetFilters = () => {
    setFilters(initialFilters);
    setFullTextSearchValue("");
    setReload(true);
  };

  // Calculate available options based on filters and load related data
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        //check if full-text filter is applied
        if (fullTextSearchValue !== "") {
          filters.fullTextProjectNumberList = await fetchFullTextData();
        }

        // calcMinMaxValues();
        const filteredData = filterData('dataset');

        setNumberOfReferences(filteredData.length);

        const newAvailableOptions: ReferencesMultiFiltersType = {
          countries: countrySelectectionisAnd ? Array.from(new Set(filteredData.map(item => item.Country.split(', ')).flat())).sort()
            : Array.from(new Set(filterData('options').map(item => item.Country.split(', ')).flat())).sort(),
          fieldsOfService: fosSelectectionisAnd ? Array.from(new Set(filteredData.map(item => item.FieldOfService.split(/;\s|,\s/)).flat())).sort()
            : Array.from(new Set(filterData('options').map(item => item.FieldOfService.split(/;\s|,\s/)).flat())).sort(),
          refNumTitles: refNoTitleSelectectionisAnd ? Array.from(new Set(filteredData.map(item => item.ProjNumTitle))).sort()
            : Array.from(new Set(filterData('options').map(item => item.ProjNumTitle))).sort(),
        };

        setAvailableOptions(newAvailableOptions);
        setFilteredData(filteredData);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [filters, countrySelectectionisAnd, fosSelectectionisAnd, refNoTitleSelectectionisAnd]);

  const fetchFullTextData = async () => {
    const fullTextProjectIDList = await postData('references/fulltextsearch', { fulltext: fullTextSearchValue }).then((response) => {
      return response;
    })
      .catch((error) => {
        console.log(error);
      });
    return fullTextProjectIDList;
  }

  //filter Items function give back a boolean value
  const filterData = (mode: 'dataset' | 'options'): ReferencesTableType[] => {
    const filteredData = data.filter(item => {
      const itemCountries = item.Country.split(', ').flat();
      const itemCountriesUnique = Array.from(new Set(itemCountries));
      const itemFieldsOfServices = item.FieldOfService?.split(/;\s|,\s/).flat();
      const itemFieldsOfServicesUnique = Array.from(new Set(itemFieldsOfServices));

      const fulltextCondition =
        fullTextSearchValue === "" ? true :
          filters.fullTextProjectNumberList.length === 0 ? false : filters.fullTextProjectNumberList.includes(item.ProjectNo);

      const countryCondition = filters.countries.length === 0 ||
        (mode === 'dataset' ? countrySelectectionisAnd ?
          filters.countries.some(country => itemCountriesUnique.includes(country)) :
          filters.countries.some(country => itemCountriesUnique.includes(country)) : true);

      const fosCondition = filters.fieldsOfServices.length === 0 ||
        (mode === 'dataset' ? fosSelectectionisAnd ?
          filters.fieldsOfServices.some(service => itemFieldsOfServicesUnique.includes(service)) :
          filters.fieldsOfServices.some(service => itemFieldsOfServicesUnique.includes(service)) : true);

      const refNumTitlesCondition = filters.refNumTitles.length === 0 ||
        (mode === 'dataset' ? (refNoTitleSelectectionisAnd ?
          filters.refNumTitles.some(title => item.ProjNumTitle.includes(title)) :
          filters.refNumTitles.some(title => item.ProjNumTitle.includes(title))) : true);

      const filteredPrintSelection = filters.printSelectionList.length > 0 ?
        filters.printSelectionList.includes(item.ProjectNo) : true;

      return dayjs(item.CompletionDate) >= filters.completionDate &&
        item.ProjectValueEuro >= filters.projectValueEuro &&
        countryCondition &&
        fosCondition &&
        (filters.selectedCompany === 'both' ? item.INTEE === true || item.INTEE === false :
          filters.selectedCompany === 'intee' ? item.INTEE === true :
            filters.selectedCompany === 'intFra' ? item.INTEE === false : false) &&
        refNumTitlesCondition && fulltextCondition && filteredPrintSelection;
    })
    return filteredData;
  };

  // const calcMinMaxValues = () => {
  //   const validDates = data
  //     .map(item => new Date(item.CompletionDate))
  //     .filter(date => !isNaN(date.getTime()));

  //   const minmaxValues = [
  //     {
  //       filter: 'year',
  //       min: Math.min(...validDates.map(date => date.getFullYear())),
  //       max: Math.max(...validDates.map(date => date.getFullYear()))
  //     },
  //     {
  //       filter: 'projectValueEuro',
  //       min: Math.min(...data.map(item => item.ProjectValueEuro)),
  //       max: Math.max(...data.map(item => item.ProjectValueEuro))
  //     }
  //   ];

  //   setMinMaxValues(minmaxValues);
  // }

  // Handler functions to update filters
  const handleYearChange = (newValue: any) => {
    setFilters((prevFilters: any) => ({ ...prevFilters, completionDate: newValue }));
  };

  const handleCountryChange = (event: any, newValue: any) => {
    setFilters((prevFilters: any) => ({ ...prevFilters, countries: newValue }));
  };

  const handleProjectValueEuroChange = (newValue: number) => {
    setFilters((prevFilters: any) => ({ ...prevFilters, projectValueEuro: newValue }));
  };

  const handleFieldOfServiceChange = (event: any, newValue: any) => {
    setFilters((prevFilters: any) => ({ ...prevFilters, fieldsOfServices: newValue }));
  };

  const handleCompanyChange = (newValue: any) => {
    console.log(newValue);
    setFilters((prevFilters: any) => ({ ...prevFilters, selectedCompany: newValue }));
  };

  const handlerefNumTitle = (event: any, newValue: any) => {
    setFilters((prevFilters: any) => ({ ...prevFilters, refNumTitles: newValue }));
  };

  const handleFullTextSearch = (searchValue: any) => {
    setFilters((prevFilters: any) => ({ ...prevFilters, fullText: searchValue }));
  };

  return (
    <>
      <Grid container spacing={1} justifyContent="space-between">
      <Grid item xs={12} md={10}>
      <Card sx={{marginTop:0}} >
      <CardContent>
        <Grid container spacing={1} justifyContent="space-between">
        <Grid item xs={12} md={6}>
          <FreeTextComponent
            onSearch={handleFullTextSearch}
            label="Full Text Search"
            inputValue={fullTextSearchValue}
            setInputValue={setFullTextSearchValue}
            setIsDialogOpen={setIsDialogOpen}
            dialogOpen={isDialogOpen}
          />
        <Button sx={{paddingTop:2}} variant="text" color="primary" onClick={() => setIsDrawerOpen((prevState: any) => !prevState)}  
        startIcon={<ArrowForward />}>
          Advanced Filters
        </Button>
        </Grid>
        <Grid item xs={12} md={5}>
          <AutocompleteComponent
            options={availableOptions.refNumTitles}
            value={filters.refNumTitles}
            handleChange={handlerefNumTitle}
            isMulti={true}
            label="Reference Number and Title"
            radioState={refNoTitleSelectectionisAnd}
            setRadioState={setrefNoTitleSelectectionisAnd}
          />
        </Grid>
        </Grid>
        </CardContent>
        </Card>
        </Grid>
        <Grid item xs={12} md={2}>         
        <CountUpComponent resetFilters = {resetFilters} end={numberOfReferences} />
        </Grid>
      </Grid>
      <CustomDrawer open={isDrawerOpen} toggleDrawer={setIsDrawerOpen} vw={20} title={'Advanced Filter'} orientation="left">
        <Grid container spacing={1} paddingTop={2}>
          <Grid item xs={10} md={10}>
            <AutocompleteComponent
              options={availableOptions.countries}
              value={filters.countries}
              handleChange={handleCountryChange}
              isMulti={true}
              label="Country Selection"
              radioState={countrySelectectionisAnd}
              setRadioState={setCountrySelectionisAnd}
            />
          </Grid>
          <Grid item xs={10} md={10}>
            <AutocompleteComponent
              options={availableOptions.fieldsOfService}
              value={filters.fieldsOfServices}
              handleChange={handleFieldOfServiceChange}
              isMulti={true}
              label="Field of Service"
              radioState={fosSelectectionisAnd}
              setRadioState={setfosSelectectionisAnd}
            />
          </Grid>
        </Grid>
        <Divider style={{ color: 'lightgrey', paddingTop: 20, paddingBottom: 20 }} />
        <Grid container spacing={1} marginTop={1}>
          <Grid item xs={12} md={12}>
            <Typography sx={{ fontWeight: 'bold', paddingBottom: 1 }}>Completion Date After</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={filters.completionDate}
                onChange={(newValue: any) => handleYearChange(newValue)}
                slotProps={{
                  textField: {
                    disabled: true,
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={12}>
            <Divider style={{ color: 'lightgrey', paddingTop: 20, paddingBottom: 20 }} />
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography sx={{ fontWeight: 'bold', paddingBottom: 1 }}>Project Value € Above </Typography>
            <Input
              value={filters.projectValueEuro}
              onChange={(event) => handleProjectValueEuroChange(Number(event.target.value))}
              inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Divider style={{ color: 'lightgrey', paddingTop: 20, paddingBottom: 20 }} />
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography sx={{ fontWeight: 'bold', paddingBottom: 1 }}>Company Selection</Typography>
            <RadiogroupComponent
              value={filters.selectedCompany}
              handleChange={handleCompanyChange}
            />
          </Grid>
        </Grid>
      </CustomDrawer>
    </>
  );
}

export default ReferenceFilters;
