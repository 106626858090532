// Import necessary components
import { MenuItem, Select, Dialog, Grid, Typography, IconButton, Divider, TextField, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Add, ArrowForward } from '@mui/icons-material';
import {LangVerType, ReferenceDetailType} from '../../types/dataTypes';
import { useState } from 'react';

// Define the props for the Blade component
type ReferenceVersionType = {
  open: boolean;
  onClose: Function;
  formData: any[];
  currentFormData: ReferenceDetailType;
  handleVersionChange: Function;
  setCurrentFormData: Function;
  toggleEditMode: Function;
  setIsNewVariant: Function;
}

function ReferenceVersion({ open, onClose, formData, setCurrentFormData, currentFormData, handleVersionChange,
  toggleEditMode, setIsNewVariant}: ReferenceVersionType) {
  const [newVariantName, setNewVariantName] = useState(''); 
  const [newDescription, setnewDescription] = useState(''); 
  
  const handleSave = () => {      
    if(chechForExistingVariantName()) {
      alert(`The variant name ${newVariantName} already exists for that Reference. Please provide a different name.`);
      return;
    }
    resetFormData();    
    toggleEditMode();
    setIsNewVariant(true);
    onClose();
  };

  const resetFormData = () => {  
    formData[0].variant_name = newVariantName;
    formData[0].variant_description = newDescription;
    setCurrentFormData(formData[0]);
  }

  const chechForExistingVariantName = () => {
    return formData.find(data => data.variant_name === newVariantName) ? true : false;     
  }

  return (
    <div>
    <Dialog open={open} onClose={() => onClose()} fullWidth>
      <Grid container style={{ padding: "10px" }}>
        <Grid item xs={12} md={12} style={{ borderLeft: "1px solid #ccc", padding: "20px" }}>
          <Grid container justifyContent="space-between" alignItems="center" style={{ marginBottom: "20px" }}>
            <Grid container>
              <Grid item xs={11}>
                <Typography variant='h5'>Reference Variants</Typography>
              </Grid>
              <Grid item xs={1}>
                <IconButton onClick={() => onClose()} sx={{ position: 'absolute', top: 10, right: 10 }}>
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid xs={12}>
                <Divider style={{ margin: "20px 0", color: "lightgrey" }} />
              </Grid>
            </Grid>
            <Grid container>
              <Typography>A Reference might have multiple variants to allow tailored printings in different languages or topics e.g. T-VET tailoered etc.
                So only specific fields do differentiate e.g. all the descriptions. 
                Every variants comes with a specified Name and a detailed description on it's purpose.</Typography>
            </Grid>
          </Grid>
          <Grid item style={{ marginBottom: "20px" }}>
            <Grid item style={{ marginBottom: "10px" }}>
              <Typography variant='h6'>Select Version</Typography>
              <Select
                label="Variant"
                value={currentFormData?.variant_name || ""}
                onChange={event => handleVersionChange(event)}
                color="primary"
                fullWidth
              >
                {Array.isArray(formData) ?  formData.map((data) => (
                  <MenuItem key={data.variant_name} value={data.variant_name}>
                    {data.variant_name}
                  </MenuItem>
                )): null}
              </Select>
            </Grid>
            <Typography variant='h6'>Description</Typography>
            <Grid container>
              <Grid item xs={10}>
                <Typography>
                  {currentFormData?.variant_description || "This variant has no description yet."}
                </Typography>
              </Grid>
              <Grid item xs={12} md={2}>
                <Button size='small' variant="text" startIcon={<ArrowForward />} color="primary" onClick={() => onClose()}>View </Button>
              </Grid>
            </Grid>
          </Grid>
          <Divider style={{ margin: "20px 0", color: "lightgrey" }} />
          <Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} style={{ marginBottom: "10px" }}>
                  <Typography variant="h6">Add new Variant</Typography>
                  <Typography>Please provide a comprehensive name for a new variant e.g. Spanish, Gender Focus and a short description
                    on the Variants purpose. 
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: "10px" }}>
                  <TextField label="Name" fullWidth onChange={(event) => setNewVariantName(event.target.value)}/>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: "10px" }}>
                  <TextField label="Description" fullWidth multiline rows={5} onChange={(event) => setnewDescription(event.target.value)}/>
                </Grid>
                <Button size='small' startIcon={<Add />} onClick={handleSave}>
                  Add
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  </div>
  );
}

export default ReferenceVersion;
