import React, { useState } from 'react';
import { Button, IconButton, TextField, Tooltip } from '@mui/material';
import { Info } from '@mui/icons-material';
import FullTextSearchInstructionsDialog from '../Filters/FullTextSearchInstructions';
import InputAdornment from '@mui/material/InputAdornment';
import { Search } from '@mui/icons-material';

function FreeTextComponent({ label, onSearch, inputValue, setInputValue,
  setIsDialogOpen, dialogOpen }: { label: string, onSearch: Function, inputValue: string, setInputValue: Function, setIsDialogOpen: Function, dialogOpen: boolean }) {

  const handleKeyPress = (e: React.KeyboardEvent) => {
    // Check if the Enter key was pressed
    if (e.key === 'Enter') {
      // Prevent the default action to avoid any side effect (e.g., form submission)
      e.preventDefault();
      // Trigger the search function
      onSearch(inputValue);
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
     <TextField
      variant="outlined"
      label={label}
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
      onKeyPress={handleKeyPress}
      style={{ flex: 1 }}
      InputLabelProps={{
        shrink: true,
        sx: {
          color: 'primary',
          "&.Mui-focused": {
            color: 'primary'
          }
        }
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Tooltip title="How to use Full Text Search">
              <IconButton onClick={() => setIsDialogOpen(true)}>
                <Info />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
      }}
      ></TextField>
      <FullTextSearchInstructionsDialog
        open={dialogOpen}
        onClose={() => setIsDialogOpen(false)}
      />
      <Button size='small' variant="text" color="primary" onClick={() => onSearch(inputValue)} startIcon={<Search />}>
      Search
      </Button>
    </div>
  );
}

export default FreeTextComponent;