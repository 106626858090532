import { getAccessTokenSilent } from "./auth.service";

export async function baseData(endpoint: string, typeRequest: 'GET' | 'POST', body?: object, responseType?: 'json' | 'blob', otherHeaders?: object
, convertBodyToJSON = true, contentType = 'application/json', acceptContent = `application/json`) {
        const accessToken = await getAccessTokenSilent();
      
        const settings = {
          method: typeRequest,
          headers: {
            Accept: acceptContent,
            'Authorization': `Bearer ${accessToken}`,
            ...otherHeaders
          }
        };

        if(contentType != 'none'){
          (settings as any).headers['Content-Type'] = contentType;
        }

        if (convertBodyToJSON) {
          (settings as any).body = JSON.stringify(body);
        }
        else{
          (settings as any).body = body;
        }

        try {
          const fetchResponse = await fetch(`${process.env.REACT_APP_API_URL}/${endpoint}`, settings);
          const data = responseType === 'blob' ? await fetchResponse.blob() : await fetchResponse.json();
          return data;
        } catch (error) {
          return error;
        }
      }

export async function getData(endpoint: string,  otherHeaders?: object) {
  return baseData(endpoint, 'GET', otherHeaders);
}

export async function postData(endpoint: string, body?: object, responseType?: 'json' | 'blob', otherHeaders?: object, convertBodyToJSON = true,
                      contentType = 'application/json', acceptContent = `application/json`) {
  return baseData(endpoint, 'POST', body, responseType, otherHeaders, convertBodyToJSON,contentType,acceptContent);
}

export async function getDataPagination(endpoint: string, page: number, limit: number, otherHeaders?: object) {
  return baseData(`${endpoint}?page=${page}&limit=${limit}`, 'GET', otherHeaders);
}

export async function postDataPagination(endpoint: string, page: number, limit: number, otherHeaders?: object) {
  return baseData(`${endpoint}?page=${page}&limit=${limit}`, 'POST', otherHeaders);
}