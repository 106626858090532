import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { getData } from "../../services/base.service";
import { ReferenceAvailableOptionsType, ReferenceDetailType } from "../../types/dataTypes";
import ReferenceForm from "../Forms/ReferenceForm";
import { initialFormData } from "../../utils/consts";
import CustomDrawer from "../Shared/CustomDrawer";

type UpdateReferenceProps = {
    setLoading: Function,
    projectNumber: string,
    setReload: Function,
    isDrawerOpen: boolean,
    setIsDrawerDetailsOpen: Function
}

function UpdateReference({ setLoading, projectNumber, setReload, isDrawerOpen, setIsDrawerDetailsOpen}: UpdateReferenceProps) {
    const [availableOptions, setAvailableOptions] = useState<ReferenceAvailableOptionsType>({ clients: [], countries: [], fieldsofServices: [], countries_homepage: []});
    const [selectedOptions, setselectedOptions] = useState<ReferenceAvailableOptionsType>({ clients: [], countries: [], fieldsofServices: [], countries_homepage: []});
    const [formData, setFormData] = useState<ReferenceDetailType[]>([initialFormData]);
    const [loadingData, setLoadingData] = useState<boolean>(true);

    const fetchData = async () => {
        try {
            if (projectNumber) {
                setLoading(true);
                // fetch project data
                let encodedProjectNumber = encodeURIComponent(projectNumber);
                await getData(`references/refDetails/${encodedProjectNumber}`).then((res) => {
                    setLoadingData(true);
                    const referenceDetails = res.referenceDetails;
                        setFormData(referenceDetails);  
                        setselectedOptions({ 
                            clients: [referenceDetails[0].short], 
                            countries: 
                            referenceDetails[0].Country != null ?
                                Array.from(new Set(referenceDetails[0].Country.split(', '))) as string[]
                                : [], 
                            fieldsofServices: 
                            referenceDetails[0].FieldOfService !=  null ?    
                                Array.from(new Set(referenceDetails[0].FieldOfService.split(/;\s|,\s/))) as string[]
                                : [], // Cast the result to a string array
                            countries_homepage: referenceDetails[0].country_homepage != null ?
                            Array.from(new Set(referenceDetails[0].country_homepage.split(', '))) as string[]
                            : []
                        });                   
                        setAvailableOptions(res.availableOptions);
                        setLoading(false);     
                        setLoadingData(false);                                     
                    });                                                
            }
        } catch (error) {
            console.error(error);
            setLoading(false);  
            setLoadingData(false);  
        }
    };

    useEffect(() => {    
        fetchData();
    }, [projectNumber]);

    return (
        !loadingData? (
            <CustomDrawer open={isDrawerOpen} toggleDrawer={setIsDrawerDetailsOpen} vw={40} title={'Project Details'} orientation="right">
            <Grid>
                <ReferenceForm setLoading={setLoading} availableOptions={availableOptions} formData={formData} type='update'  selectedOptions={selectedOptions}
                setSelectedOptions={setselectedOptions} setReload={setReload} handleClose={setIsDrawerDetailsOpen}/>
            </Grid>
            </CustomDrawer>
        ) : null // Render nothing if expertData or expertData.expertsDetails is null
    )
}

export default UpdateReference;



