import { Button } from "@mui/material";
import { useState } from "react";
import { uploadDocumentToSharePoint } from "../../services/sharepoint.service";
import { PublishedWithChanges } from "@mui/icons-material";

type ConsentUploadProps = {
    setConsentFileName: Function,
    accessToken: string,
    type: string,
    isEditMode: boolean
};

function ConsentUpload({ setConsentFileName, accessToken, type, isEditMode }: ConsentUploadProps) {
    const [isUploadSuccessful, setIsUploadSuccessful] = useState(false);

    const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            if (event.target.files) {
                const file = event.target.files[0];
                const fileType = file.name.split('.').pop()?.toLowerCase();

                if (fileType !== 'exe' && fileType !== 'zip' && fileType !== 'rar') {
                    await uploadDocumentToSharePoint(file, accessToken, 'consent/');
                    setConsentFileName(file.name);
                    setIsUploadSuccessful(true);
                } else {
                    alert('Invalid file type. The forbidden file types are .exe, .zip, and .rar. Please select a different file.');
                }
            }
        } catch (error) {
            console.error('Error uploading file to SharePoint', error);
        }
    }

    return (
        <div>
            <Button startIcon={<PublishedWithChanges />} variant="outlined" component="label" disabled={!isEditMode || isUploadSuccessful}>
                {type == 'add' ? 'Upload Consent File' : 'Replace Consent File'}
                <input type="file" hidden onChange={handleFileUpload} />
            </Button>
        </div>
    )
}
export default ConsentUpload;