/** @jsxImportSource @emotion/react */
import { useEffect, useMemo, useState } from "react";
import { getData, postData } from "../services/base.service";
import ReferenceFilters from "../components/Filters/ReferenceFilters";
import { Container, Backdrop, Fade, Grid, makeStyles, styled, Box, Fab, Button, Dialog, Card, CardContent, Menu, MenuItem, ListItemIcon, Typography, ToggleButton, List } from '@mui/material';
import Logo from '../assets/int_logo.png'; // replace with the path to your logo
import { ReferencesTableType, ReferencesMultiFiltersType, ReferencesFilterType, MenuItemProps } from "../types/dataTypes";
import { pulseAnimation } from '../styles/styles';
import { initialFilters } from "../utils/consts";
import ScrollTop from "../components/Navigation/ScrollTop";
import { Add, Apartment, ArrowForward, DataObject, Download, Euro, FormatListBulleted, Handshake, Inventory, KeyboardArrowDown, KeyboardArrowUp, Layers, ManageSearch, Public, RocketLaunch, Sort, SwitchRight, Sync, Tag, Title, ToggleOn, Verified, WebAsset } from "@mui/icons-material";
import PrintTemplates from "../components/Popups/PrintTemplates";
import ReferenceCard from "../components/Cards/ReferenceCard";
import UpdateReference from "../components/Popups/UpdateReference";
import AddReference from "../components/Popups/AddReference";
import TemplatesManagement from "../components/Popups/TemplatesManagement";
import ReferenceSelectionShoppingCart from "../components/Shared/ReferenceSelectionShoppingCart";
import { useTheme } from '@mui/material/styles';
import ReferenceTable from "../components/Tables/ReferenceTable";
import { isDataView } from "util/types";
import MetadataManagement from "../components/Popups/MetadataManagement";
import SortFeature from "../components/Shared/SortFeature";
import CustomMenu from "../components/Shared/CustomMenue";

type ReferencesViewProps = {
  userRoles: string[];
};

function ReferencesView({ userRoles }: ReferencesViewProps) {
  const [data, setData] = useState<ReferencesTableType[]>([]);
  const [dissolvedFilters, setDissolvedFilters] = useState<ReferencesMultiFiltersType>({} as ReferencesMultiFiltersType);
  const [filteredData, setFilteredData] = useState<ReferencesTableType[]>([]);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [filters, setFilters] = useState(initialFilters);
  const [isDrawerAdvancedFilterOpen, setIsDrawerDrawerAdvancedFilterOpen] = useState(false);
  const [isDrawerDetailsOpen, setIsDrawerDetailsOpen] = useState(false);
  const [selectedReference, setSelectedReference] = useState<string[]>([]);
  const [detailsUpdateDialogOpen, setdetailsUpdateDialogOpen] = useState(false);
  const [numCardsToDisplay, setNumCardsToDisplay] = useState(25);
  const [sortConfig, setSortConfig] = useState({ key: "StartDate", direction: "desc" });
  const [manageTemplatesDialogOpen, setManageTemplatesDialogOpen] = useState(false);
  const [manageMetaDataDialogOpen, setManageMetaDataDialogOpen] = useState(false);
  const [detailsAddDialogOpen, setdetailsAddDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [detailsSelected, setDetailsSelected] = useState<string>('');
  const [isTableView, setIsTableView] = useState<boolean>(false);

  const theme = useTheme();

  useEffect(() => {
    fetchData();
  }, [reload]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await getData('references/reftable');
      setData(result.referenceData as ReferencesTableType[]);
      setFilteredData(result.referenceData as ReferencesTableType[]);
      setDissolvedFilters(result.dissolvedFilters as ReferencesMultiFiltersType);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Function to handle the "More" button click
  const handleMoreClick = () => {
    setNumCardsToDisplay(numCardsToDisplay + 25);
  };

  const handledetailsUpdateDialogOpen = (projectNo: string) => {
    setDetailsSelected(projectNo);
    setIsDrawerDetailsOpen(true);
  };

  const handleSelectReference = (projectNo: string) => {
    if (selectedReference.includes(projectNo)) {
      setSelectedReference(selectedReference.filter((x) => x !== projectNo));
    } else {
      setSelectedReference([...selectedReference, projectNo]);
    }
  };

  const sortedData = useMemo(() => {
    let sortableData = [...filteredData];
    if (sortConfig !== null) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [filteredData, sortConfig]);

  const handleManageTemplatesDialogOpen = () => {
    setManageTemplatesDialogOpen(true);
  };

  const handleManageMetaDataDialogOpen = () => {
    setManageMetaDataDialogOpen(true);
  };

  const handleManageMetaDataDialogClose = () => {
    setManageMetaDataDialogOpen(false);
  };

  const handleManageTemplatesDialogClose = () => {
    setManageTemplatesDialogOpen(false);
  };

  const handleManageOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleManageClose = () => {
    setAnchorEl(null);
  };

  const handledetailsAddDialogOpen = () => {
    alert(`Create a new reference by filling the form below. Make sure the initial Reference is always in English and should be in a very general style. 
        Afterwards you can add multiple Variants in different languages or specialized topics via Edit Reference. 
        Please note that the fields marked with * are mandatory.`);
    setdetailsAddDialogOpen(true);
  };

  const handledetailsAddDialogClose = () => {
    setdetailsAddDialogOpen(false);
  };

  const handleHomepageSync = async () => {
    setLoading(true);
    const response = await postData("references/exportReferencesToHomepage", {}, "blob");
    setLoading(false);
    const url = window.URL.createObjectURL(response);
    const link = document.createElement("a");
    link.href = url;
    const fileName = "references_to_homepage_" + new Date().toISOString() + ".csv";
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    if (link.parentNode !== null) {
      link.parentNode.removeChild(link);
    }
  };

  const menuItems: MenuItemProps[] = [
    {
      name: 'Add Reference',
      IconButton: Add,
      onClickFunction: handledetailsAddDialogOpen,
    },
    {
      name: 'Manage Templates',
      IconButton: Inventory,
      onClickFunction: handleManageTemplatesDialogOpen,
      adminOnly: true,
    },
    {
      name: 'Manage Meta Data',
      IconButton: FormatListBulleted,
      onClickFunction: handleManageMetaDataDialogOpen,
      adminOnly: true,
    },
    {
      name: 'Sync References To Homepage',
      IconButton: Sync,
      onClickFunction: handleHomepageSync,
      adminOnly: true,
    },
  ];


  return (
    data.length > 0 ? (
      <Container>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            transition: 'margin 0.3s ease',
            marginLeft: isDrawerAdvancedFilterOpen ? `20vw` : 0,
            marginRight: isDrawerDetailsOpen ? `40vw` : 0,
            overflow: 'auto',
          }}
        >
          <Grid>
            <Card sx={{ margin: 1 }}>
              <CardContent>
                <ReferenceFilters data={data} setFilteredData={setFilteredData} setLoading={setLoading} setReload={setReload}
                  filters={filters} setFilters={setFilters} isDrawerOpen={isDrawerAdvancedFilterOpen} setIsDrawerOpen={setIsDrawerDrawerAdvancedFilterOpen} />
                <Grid container spacing={1} justifyContent='space-between'>
                  <Grid item xs={12} md={4}>
                    <ReferenceSelectionShoppingCart selectedReferences={selectedReference} setSelectedReferences={setSelectedReference}
                      setFilters={setFilters} setReload={setReload} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SortFeature
                      fieldList={["ProjectNo", "ProjectTitle", "Client", "Country", "ProjectValueEuro", "StartDate", "CompletionDate",
                        "INTEE", "Homepage", "FieldOfService"]}
                      fieldIconList={[<Tag />, <Title />, <Handshake />, <Public />, <Euro />, <RocketLaunch />, <Verified />,
                      <Apartment />, <WebAsset />, <Layers />]}
                      sortConfig={sortConfig}
                      setSortConfig={setSortConfig} />
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <Button size="small" variant="outlined" color="primary" onClick={() => { isTableView ? setIsTableView(false) : setIsTableView(true) }}
                      startIcon={<SwitchRight />}>
                      {isTableView ? 'Card View' : 'Table View'}
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={2}>
                  <CustomMenu
                    anchorEl={anchorEl}
                    handleClose={handleManageClose}
                    menuItems={menuItems}
                    handleManageOpen={handleManageOpen}
                    userRoles={userRoles}
                  />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div id="back-to-top-anchor" />
                <ScrollTop>
                  <Fab color="primary" size="small" aria-label="scroll back to top">
                    <KeyboardArrowUp />
                  </Fab>
                </ScrollTop>
              </Grid>
              {isTableView ? (
                <Grid container spacing={0.5} justifyContent='center'>
                  <ReferenceTable
                    items={sortedData.slice(0, numCardsToDisplay)}
                    isSelected={(projectNo: string) => selectedReference.includes(projectNo)}
                    selectedProjectNo={detailsSelected}
                    onDetailsClick={handledetailsUpdateDialogOpen}
                    onSelectChange={handleSelectReference}
                  />
                  {sortedData.length > numCardsToDisplay && (
                    <Grid item xs={12} sm={12} md={12} >
                      <Box display="flex" justifyContent="center" paddingTop={20} paddingBottom={20}>
                        <Button startIcon={<ArrowForward />} variant="outlined" color="primary" onClick={handleMoreClick}>
                          Load more References
                        </Button>
                      </Box>
                    </Grid>
                  )}
                </Grid>) : (
                <Grid container spacing={2} justifyContent='center'>
                  {sortedData.slice(0, numCardsToDisplay).map((item, index) => (
                    <Grid item xs={12} sm={12} md={6} key={index}>
                      <ReferenceCard
                        item={item}
                        isSelected={selectedReference.includes(item.ProjectNo)}
                        onDetailsClick={handledetailsUpdateDialogOpen}
                        onSelectChange={handleSelectReference}
                        setDrawerDetailsOpen={setIsDrawerDetailsOpen}
                      />
                    </Grid>
                  ))}
                  {sortedData.length > numCardsToDisplay && (
                    <Grid item xs={12} sm={12} md={12} >
                      <Box display="flex" justifyContent="center" paddingTop={20} paddingBottom={20}>
                        <Button startIcon={<ArrowForward />} variant="outlined" color="primary" onClick={handleMoreClick}>
                          Load more References
                        </Button>
                      </Box>
                    </Grid>
                  )}
                </Grid>)}
              <UpdateReference setLoading={setLoading} projectNumber={detailsSelected || ""} setReload={setReload}
                isDrawerOpen={isDrawerDetailsOpen} setIsDrawerDetailsOpen={setIsDrawerDetailsOpen} />
              <Dialog open={detailsAddDialogOpen} onClose={handledetailsAddDialogClose} fullScreen>
                <AddReference setLoading={setLoading} handleClose={handledetailsAddDialogClose} setReload={setReload} />
              </Dialog>
              <Dialog open={manageTemplatesDialogOpen} onClose={handleManageTemplatesDialogClose} fullWidth maxWidth="lg">
                <TemplatesManagement handleClose={handleManageTemplatesDialogClose} />
              </Dialog>
              <Dialog open={manageMetaDataDialogOpen} onClose={handleManageMetaDataDialogClose} fullWidth maxWidth="sm">
                <MetadataManagement handleClose={handleManageMetaDataDialogClose} database="references"/>
              </Dialog>
            </Grid>
            ─=≡Σ((( つ◕ل͜◕)つ
          </Grid>
          <Backdrop open={loading} style={{ zIndex: 9999, color: '#fff' }}>
            <Fade in={loading}>
              <img src={theme.logo} alt="Company Logo" css={pulseAnimation} />
            </Fade>
          </Backdrop>
        </Box>
      </Container>
    ) : null
  );
};

export default ReferencesView;