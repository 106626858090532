import { Grid, IconButton, Tooltip, Typography, Box } from "@mui/material";
import { FlagIcon } from "react-flag-kit";
import { countryToCode } from "../../utils/country_code_mapping";

type IconTextRowProps = {
    title: string;
    IconComponent: React.ElementType;
    displayText: string | number;
    country?: string;
}

function IconTextRow({title, IconComponent, displayText, country}: IconTextRowProps) {
    return(
        <Grid item container direction="row" alignItems="center">
            <Tooltip title={title}>
                <IconButton sx={{ padding: '10px'}}>
                    {country != undefined 
                    ? (countryToCode[country] != undefined 
                        ? <div>
                            <FlagIcon code={countryToCode[country]} size={25}/>
                          </div> 
                        : <IconComponent style={{ color: 'black' }}/>)
                    : <IconComponent style={{ color: 'black' }}/>}
                </IconButton>
            </Tooltip>
            <Typography variant="body1">{displayText}</Typography>
        </Grid>
    )
}

export default IconTextRow;