import React, { useEffect, useState, useRef } from 'react';
import { getData, postData } from '../../services/base.service';
import { Button, Grid, List, ListItem, ListItemText, ListItemIcon, Typography, IconButton, Link } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';


type TemplatesManagementProps = {
  handleClose: Function;
}

function TemplatesManagement({ handleClose }: TemplatesManagementProps) {
  const [templates, setTemplates] = useState(['']);
  const fileInputRef = useRef(null);

  useEffect(() => {
    getData('templates').then((data: any) => setTemplates(data));
  }, []);

  const deleteTemplate = (name: string) => {
    try {
      postData(`templates/delete/${name}`).then(() =>
        setTemplates(templates.filter((template: string) => template !== name)));
      alert(`Template with name ${name} has been deleted.`);
    } catch (error) {
      alert(`The following error occurred: ${error}`);
    }
  };

  const downloadTemplate = (name: string) => {
    try {
      postData(`templates/download/${name}`,undefined,"blob").then((data: any) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
      });
    }
      catch (error) {
        alert(`The following error occurred: ${error}`);
      }
  };

  const addTemplate = (event: any) => {
    try {
      const file = event.target.files[0];
      const fileType = file.name.split('.').pop()?.toLowerCase();

      if (fileType != 'docx') {
        alert('Please upload a .docx file.')
        return;
      }
      //check if the filename is already in the list
      if (templates) {
        if (templates.includes(file.name)) {
          alert("Another template with the same name already exists. Please rename the file and try again.");
          return;
        }
      }

      const formData = new FormData();
      formData.append('file', file);
      postData('templates/add', formData, 'json', undefined, false, 'none', '*/*').then(() =>
        setTemplates([...templates, file.name])
      );
      alert(`Template with name ${file.name} has been added.`)

    }
    catch (error) {
      alert(`The following error occurred: ${error}`);
    }
  };

  const handleAddTemplateClick = () => {
    if (fileInputRef.current) {
      (fileInputRef.current as HTMLInputElement).click();
    }
  };

  return (
    <Grid margin={3}>
   <Grid container spacing={2}>
  <Grid item xs={6}>
    <Typography variant="h5">Templates Management</Typography>
  </Grid>
  <Grid item xs={6} container justifyContent="flex-end">
    <IconButton onClick={() => handleClose()}>
      <CloseIcon />
    </IconButton>
  </Grid>
  <Grid item xs={12}>
  <Typography>Add or remove Refenrence Templates. The templates need a specific format. Please review the {' '}
    <Link href='/How_to_Manage_Reference_Templates_03.04.2024.pdf' target="_blank" rel="noopener noreferrer">
      How To
    </Link>
  </Typography>
  </Grid>
</Grid>
    <Grid container spacing={2}>
      <Grid item xs={12} margin={2}>
        <Typography>Available Templates</Typography>
        {templates.length > 0 ?
          <List>
            {templates.map((template: string) => (
              <ListItem key={template}>
                <ListItemIcon>
                  <InsertDriveFileIcon />
                </ListItemIcon>
                <ListItemText primary={template} />
                <Button color='primary' startIcon={<DownloadIcon />} onClick={() => downloadTemplate(template)}>Download</Button>
                <Button color='error' startIcon={<DeleteIcon />} onClick={() => deleteTemplate(template)}>Delete</Button>
              </ListItem>
            ))}
          </List>
          : null}
      </Grid>
      <Grid item xs={12}>
        <Button color="primary" startIcon={<AddIcon />} onClick={handleAddTemplateClick}>Add New Template</Button>
        <input
          type="file"
          onChange={addTemplate}
          style={{ display: 'none' }}
          ref={fileInputRef}
        />
      </Grid>
    </Grid>
  </Grid>
  );
};

export default TemplatesManagement;