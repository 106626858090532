import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  Card,
} from '@mui/material';
import { ReferencesTableType } from '../../types/dataTypes';
import { Print, ArrowForward } from '@mui/icons-material';
import { formatCurrency } from '../../utils/utilFunctions';

type ReferenceTableProps = {
  items: ReferencesTableType[];
  isSelected: (projectNo: string) => boolean;
  selectedProjectNo : string;
  onDetailsClick: (projectNo: string) => void;
  onSelectChange: (projectNo: string) => void;
};

const ReferenceTable = ({ items, isSelected, selectedProjectNo, onDetailsClick, onSelectChange }: ReferenceTableProps) => {
  const screenHeight = window.innerHeight *0.61;

    return (
        <Card>
          <TableContainer component={Paper}>
          <Table>
          <TableHead>
            <TableRow>
            <TableCell sx={{ width: '30px', fontWeight: 'bold', position: 'sticky', top: 0, backgroundColor: '#fff', padding: '10px' }}>Project No</TableCell>
            <TableCell sx={{ width: '200px', fontWeight: 'bold', position: 'sticky', top: 0, backgroundColor: '#fff', padding: '10px' }}>Project Title</TableCell>
            <TableCell sx={{ width: '30px', fontWeight: 'bold', position: 'sticky', top: 0, backgroundColor: '#fff', padding: '10px' }}>Client</TableCell>
            <TableCell sx={{ width: '50px', fontWeight: 'bold', position: 'sticky', top: 0, backgroundColor: '#fff', padding: '10px' }}>Country</TableCell>
            <TableCell sx={{ width: '30px', fontWeight: 'bold', position: 'sticky', top: 0, backgroundColor: '#fff', padding: '10px' }}>Project Value</TableCell>
            <TableCell sx={{ width: '90px', fontWeight: 'bold', position: 'sticky', top: 0, backgroundColor: '#fff', padding: '10px' }}>Start Date</TableCell>
            <TableCell sx={{ width: '90px', fontWeight: 'bold', position: 'sticky', top: 0, backgroundColor: '#fff', padding: '10px' }}>Completion Date</TableCell>
            <TableCell sx={{ width: '80px', fontWeight: 'bold', position: 'sticky', top: 0, backgroundColor: '#fff', padding: '10px' }}>Company Name</TableCell>
            <TableCell sx={{ width: '30px', fontWeight: 'bold', position: 'sticky', top: 0, backgroundColor: '#fff', padding: '10px' }}>Homepage</TableCell>
            <TableCell sx={{ width: '200px', fontWeight: 'bold', position: 'sticky', top: 0, backgroundColor: '#fff', padding: '10px' }}>Field of Service</TableCell>
            <TableCell sx={{ width: '30px', fontWeight: 'bold', position: 'sticky', top: 0, backgroundColor: '#fff', padding: '10px' }}>Select to Print</TableCell>
            <TableCell sx={{ width: '30px', fontWeight: 'bold', position: 'sticky', top: 0, backgroundColor: '#fff', padding: '10px' }}>View Details</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
              {items.map((item) => (
                  <TableRow
                  key={item.ProjectNo}
                  sx={{
                    backgroundColor: selectedProjectNo === item.ProjectNo ? '#f0f0f0' : 'inherit',
                  }}
                >
                  <TableCell>{item.ProjectNo}</TableCell>
                  <TableCell>{item.ProjectTitle}</TableCell>
                  <TableCell>{item.Client_short}</TableCell>
                  <TableCell>{item.Country}</TableCell>
                  <TableCell>{formatCurrency(item.ProjectValueEuro)}</TableCell>
                  <TableCell>{item.StartDate?.toString()}</TableCell>
                  <TableCell>{item.CompletionDate?.toString()}</TableCell>
                  <TableCell>{item.INTEE ? 'INT_EE' : 'INT_FRA'}</TableCell>
                  <TableCell>{item.Homepage ? 'Yes' : 'No'}</TableCell>
                  <TableCell>{item.FieldOfService}</TableCell>
                  <TableCell>
                    <Checkbox
                      checked={isSelected(item.ProjectNo)}
                      onChange={() => onSelectChange(item.ProjectNo)}
                      icon={<Print color="disabled" />}
                      checkedIcon={<Print color="primary" />}
                    />
                  </TableCell>
                  <TableCell>
                    <Tooltip title="View Details">
                      <IconButton onClick={() => onDetailsClick(item.ProjectNo)}>
                        <ArrowForward />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </Card>
      );
};

export default ReferenceTable;