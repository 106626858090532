import React from 'react';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';

type CustomDrawerProps = {
  open: boolean;
  toggleDrawer: Function;
  width?: number;
  children: React.ReactNode;
  title: string;
  orientation?: 'left' | 'right'; // New prop
  vw: number;
}


function CustomDrawer({ open, toggleDrawer, vw, children, title, orientation = 'left' }: CustomDrawerProps) {
  const transformvwToPx = (vw: number) => {
    return (vw * window.innerWidth) / 100;
  }

  return (
    <Box
      sx={{
        width: transformvwToPx(vw),
        position: 'fixed',
        top: 0,
        [orientation]: open ? 0 : -transformvwToPx(vw), // Use the orientation prop here
        height: '100%',
        backgroundColor: 'white',
        boxShadow: 3,
        zIndex: 1300,
        transition: `${orientation} 0.3s ease`, // And here
        overflow: 'auto'
      }}
    >
      <Grid container sx={{ padding: 2, borderBottom: 1, borderColor: 'lightgrey' }}>
        <Grid item xs={12}>
          <Typography variant="h5">{title}</Typography>
        </Grid>
        <Grid item xs={12}>
          <IconButton onClick={() => toggleDrawer((prevState: any) => !prevState)} sx={{ position: 'absolute', top: 10, right: 10 }}>
            <Close />
          </IconButton>
        </Grid>
      </Grid>
      <Box sx={{ padding: 2 }}>
        {children}
      </Box>
    </Box>
  );
};

export default CustomDrawer;