import { createTheme } from "@mui/material/styles";
import logoINTEE from '../assets/intee_logo.png';
import logoINTFRA from '../assets/intfra_logo.png';

declare module '@mui/material/styles' {
  interface Theme {
    logo: string;
  }
  interface ThemeOptions {
    logo?: string;
  }
}

const COLORS = {
  BRAND: { GREEN: "#009e61", GRAY: "#616362​", BLUE:"#009BA0" },
  SECONDARY: {
    BLUE: "#009ECB",
    YELLOW: "#FFCA00​",
    GREEN: "#85BA6A",
    PURPLE: "#5B609D",
    BLUE_GRAY: "#99B0BA​",
    PINK: "#99B0BA​",
  },
};

export const themeINTEE = createTheme({
  logo: logoINTEE,
  typography: {
    fontFamily: ["Helvetica", "Arial"].join(","),
    h2: {
      fontSize: '0.5 rem',
      fontWeight: 600,
      marginBottom: '20px',
    },
    h1: {
      fontSize: '1.0 rem'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: COLORS.BRAND.GREEN,
    },
    secondary: {
      main: COLORS.SECONDARY.BLUE,
    },
    error: {
      main: "#c00000",
    },
    text: {
      primary: COLORS.BRAND.GRAY,
    },
    info: {
      main: COLORS.SECONDARY.PURPLE,
    },
    success: {
      main:  COLORS.SECONDARY.GREEN,
    },
    divider: COLORS.SECONDARY.BLUE_GRAY,
  },
  shape: {
    borderRadius: 0,
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          margin: '20px'
        }
      }
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: "none !important", // Add this line
        },
      },
    },
  },
});



export const themeINTFRA = createTheme({
  logo: logoINTFRA,
  typography: {
    fontFamily: ["Helvetica", "Arial"].join(","),
    h2: {
      fontSize: '0.5 rem',
      fontWeight: 600,
      marginBottom: '20px',
    },
    h1: {
      fontSize: '1.0 rem'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: COLORS.BRAND.BLUE,
    },
    secondary: {
      main: COLORS.SECONDARY.BLUE,
    },
    error: {
      main: "#c00000",
    },
    text: {
      primary: COLORS.BRAND.GRAY,
    },
    info: {
      main: COLORS.SECONDARY.PURPLE,
    },
    success: {
      main:  COLORS.SECONDARY.GREEN,
    },
    divider: COLORS.SECONDARY.BLUE_GRAY,
  },
  shape: {
    borderRadius: 0,
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          margin: '20px'
        }
      }
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: "none !important", // Add this line
        },
      },
    },
  },
});
