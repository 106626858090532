import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Typography, Box } from '@mui/material';

function FullTextSearchInstructionsDialog({ open, onClose }: { open: boolean, onClose: () => void }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>How to use Full Text Search</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body1">
            <Box fontWeight="fontWeightBold">Use the <b>AND</b> keyword to search for records that contain both words.</Box>
            <Box fontStyle="italic">Example: water <b>AND</b>  sanitation will return records that contain both words.</Box>
          </Typography>
          <br/>
          <Typography variant="body1">
            <Box fontWeight="fontWeightBold">Use the doublequotes <b>" "</b> to search for records that contain the exact phrase.</Box>
            <Box fontStyle="italic">Example: "water sanitation" will return records that contain the exact phrase.</Box>
          </Typography>
          <br/>
          <Typography variant="body1">
            <Box fontWeight="fontWeightBold">Use the <b>*</b> to search for records that contain words that start or end with the search term.</Box>
            <Box fontStyle="italic">Example: financ* will return keywords variations like finance, financial, finances, etc.</Box>
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default FullTextSearchInstructionsDialog;