import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';



export const LineStyle = styled.div<{ color?: string, thickness?: string, width?: string, dashed?: boolean }>(({ color = '#000000', thickness = "3px", width = "20px", dashed = false }) => `
  ${dashed ? `background: repeating-linear-gradient(90deg,${color} 0 3px,#0000 0 5px);` : `background-color: ${color};`}
  width: ${width};
  height: ${thickness};
`)

export const PointStyle = styled.div<{ color?: string, diameter?: string}>((
  { color = '#000000', diameter = "3px", }) => `
  background-color: ${color};
  width: ${diameter};
  height: ${diameter};
  border-radius: 50%;
`)

export const HrStyle = styled.hr<{}>(() => `
  border-bottom: none;
  border-left: none;
  border-right: none;
  margin: 10px 0;
`)

export const FontSmallStyle = styled.span<{ color?: string }>(
  ({ color = 'inherit' }) => `
  font-size: 0.9em;
  font-weight: bold;
  display: flex;
  align-items: center;
  color: ${color};
`)

export const MapStyle = styled.div<{}>(() => `
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`)

export const ButtonStyle = styled.button<{ width?: string, height?: string, filledStyle?: boolean, buttonStyle?: string, padding?: string }>(
  ({ width = 'auto', height = '100%', disabled = false, filledStyle = false, buttonStyle = 'symbol', padding = '0px' }) => `
    font-size: 16px;
    height: ${height};
    width:  ${width};
    padding: ${padding};
    background-color: ${filledStyle ? `#000000` : '#ffffff00'};
    font-weight: ${(buttonStyle === 'secondary') ? 'bold' : 'normal'};
    border-style: none;
    padding: ${filledStyle ? '10px 15px' : '0px'};
    margin: ${filledStyle ? '5px' : '0px'};
    border-radius: 3px;
    color: ${filledStyle ? `#000000` : (buttonStyle === 'secondary') ? `#000000` : 'inherit'};
    ${disabled ? 'cursor: not-allowed;' : ''}
    ${(buttonStyle === 'secondary') ? `
        text-decoration-line: underline;
        text-transform: uppercase;
    `: ''}
    ${!disabled ? `:hover{
        cursor: pointer;
    }`: ''}
`)

export const FieldsetStyle = styled.fieldset<{}>(() => `
  border: 0;
  margin: 0;
  padding: 0;  
`)

export const LegendStyle = styled.div<{ templateColumn?: string }>(({ templateColumn = '170px' }) => `
display: grid;
grid-template-columns: 40px ${templateColumn};
justify-content: center;
align-items: center;
p{
  padding: 5px 0;
}
> div{
  display: flex;
  justify-content: center;
}
`)

export const OverlayContainerStyle = styled.div<{}>(() => `
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    color: '#000000';
    z-index: 7000;
    pointer-events: none;
`)

export const ButtonContainerStyle = styled.div<{}>(() => `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 0 10px 0;
    > * {
        margin: 10px;
    }
`)

export const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

export const pulseAnimation = css`
  animation: ${pulse} 2s infinite;
`;

