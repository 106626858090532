import React, { useEffect } from 'react';
import { TreeView } from '@mui/x-tree-view';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { alpha, Button, IconButton, Typography } from '@mui/material';
import { Clear, Search } from '@mui/icons-material';

type TreeNode = {
  id: string;
  label: string;
  children: TreeNode[];
};


function findNodeById(nodes: TreeNode[], id: string): TreeNode | undefined {
  for (let node of nodes) {
    console.log(`Checking node: ${node.id}`);
    if (node.id === id) {
      console.log(`Node found: ${node.id}`);
      return node;
    }
    if (node.children && node.children.length > 0) {
      const childNode = findNodeById(node.children, id);
      if (childNode) {
        console.log(`Child node found: ${childNode.id}`);
        return childNode;
      }
    }
    
  }
  return undefined;
}

function getParentLabel(nodes: TreeNode[], id: string): string {
  for (let node of nodes) {
    if (node.children) {
      if (node.children.some(child => child.id === id)) {
        return node.label;
      }
      for (let child of node.children) {
        const parentLabel = getParentLabel([child], id);
        if (parentLabel) {
          return parentLabel;
        }
      }
    }
  }
  return '';
}

function renderTree(nodes: TreeNode[]): JSX.Element[] {
  return nodes.map((node) => (
    <TreeItem key={node.id} nodeId={node.id} label={node.label}>
      {Array.isArray(node.children) ? renderTree(node.children) : null}
    </TreeItem>
  ));
}

export default function ControlledTreeView({
  data,
  handleChange,
  radioState = undefined,
  setRadioState = undefined,
  selectedKeywords = [],
  mode = 'search',
  enabled = false
}:
  {
    data: TreeNode[],
    handleChange: Function,
    radioState?: boolean,
    setRadioState?: Function,
    selectedKeywords?: TreeNode[],
    mode?: 'search' | 'edit',
    enabled?: boolean
  }) {
  const [selected, setSelected] = React.useState<TreeNode[]>([]);

  useEffect(() => {
    setSelected(getTreeNodesWithChildren(selectedKeywords));
  },[]);

  const handleNodeSelect = (event: React.SyntheticEvent, nodeId: string) => {
    const node = findNodeById(data, nodeId);
    const parentLabel = getParentLabel(data, nodeId);
    let newSelected = selected;
  
    if (node) {
      if (node.children && node.children.length > 0) {
        // Do not select parent nodes with children
        return;
      } else {
        // Select parent nodes without children or leaf nodes
        const nodeLabel = parentLabel ? `${parentLabel} - ${node.label}` : node.label;
        if (selected.some(selectedNode => selectedNode.label === nodeLabel)) {
          newSelected = selected.filter(selectedNode => selectedNode.label !== nodeLabel);
        } else {
          newSelected = [...selected, { id: nodeId, label: nodeLabel, children: [] }];
        }
        setSelected(newSelected);
  
        if (mode === 'edit') {
          handleChange(newSelected);
        }
      }
    }
  };
  

  const handleClear = () => {
    setSelected([]);
    const clearEvent = new Event('clear', { bubbles: true });
    handleChange(clearEvent as unknown as React.SyntheticEvent<Element, Event>, []);
  };

  const handleSearch = () => {
    handleChange(new Event('search', { bubbles: true }) as unknown as React.SyntheticEvent<Element, Event>, selected);
  };

  const handleChipDelete = (id: string) => {
    setSelected((prevSelected) => {
      const updatedSelected = prevSelected.filter(selectedNode => selectedNode.id !== id);
      if (mode === 'edit') {
        handleChange(updatedSelected);
      }
      return updatedSelected;
    });
  };

  const getTreeItemIds = (nodes: TreeNode[]): string[] => {
    let ids: string[] = [];
    for (let node of nodes) {
      ids.push(node.id);
      if (node.children && node.children.length > 0) {
        ids = ids.concat(getTreeItemIds(node.children));
      }
    }
    return ids;
  };

  const getTreeNodesWithChildren = (nodes: TreeNode[]): TreeNode[] => {
    // Empty array to store nodes with children
    let nodesWithChildren: TreeNode[] = [];
    
    // Iterate over each node
    for (let node of nodes) {
      if (node.children && node.children.length > 0) {
        // Change the label of children to "Parent - Child"
        node.children.forEach(child => {
          if(child.label.includes(' - ')){
            child.label = child.label
          }
          else{
            child.label = `${node.label} - ${child.label}`;
          }
          
          // Add the child node to the array
          nodesWithChildren.push(child);
        });
      } else {
        // Add the node to the array if it has no children
        nodesWithChildren.push(node);
      }
    }
    
    return nodesWithChildren;
  };

  return (
    <div>
      <Typography sx={{ fontWeight: 'bold', paddingBottom: 1 }}>Keyword Selection</Typography>
      <Box
        height="300px"
        overflow="auto"
        width='300px'
        padding="16px"
        bgcolor={alpha('#000000', 0.04)}
      >
        <Box
          display="flex"
          flexWrap="wrap"
          gap={1}
          marginBottom={1}
          position="sticky"
          top={0}
          bgcolor="#fff"
          zIndex={1}
          padding="8px"
        >
          {selected.map((node) => (
            <Chip
              key={node.id}
              label={node.label}
              color="primary"
              onDelete={enabled ? () => handleChipDelete(node.id) : undefined}
            />
          ))}
          {radioState !== undefined && setRadioState !== undefined ? (
            <IconButton
              onClick={enabled ? () => setRadioState(!radioState) : undefined}
              size="small"
              sx={{ marginLeft: 'auto' }}
            >
              {radioState ? "AND" : "OR"}
            </IconButton>
          ) : null}
        </Box>
        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          onNodeSelect={enabled ? handleNodeSelect : undefined}
        >
          {renderTree(data)}
        </TreeView>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        padding="16px"
      >
        {mode === 'search' ? (
          <Button
            startIcon={<Search />}
            variant="contained"
            color="primary"
            onClick={handleSearch}
            size="small"
          >
            Search
          </Button>
        ) : null}
        <Button
          disabled={!enabled}
          startIcon={<Clear />}
          variant="contained"
          color="primary"
          onClick={handleClear}
          size="small"
        >
          Clear
        </Button>
      </Box>
    </div>
  );
}
