import React, { useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { AccountCircle, ExitToApp } from "@mui/icons-material";
import { useMsal } from "@azure/msal-react";
import { UserType } from "../../types/dataTypes";

type MenuProps = {
  user: UserType;
};

function UserMenu({ user }: MenuProps) {
  const { instance } = useMsal();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    instance.logout();
    handleClose();
  };

  return (
    <div>
      <IconButton
        edge="end"
        aria-label="account of current user"
        aria-haspopup="true"
        color="inherit"
        onClick={handleMenu}
      >
        <AccountCircle />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>{user}</MenuItem>
        <MenuItem onClick={handleLogout}>
          <ExitToApp
            sx={{
              marginRight: (theme) => theme.spacing(1),
            }}
          />
          Log out
        </MenuItem>
      </Menu>
    </div>
  );
}

export default UserMenu;
