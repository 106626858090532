import React, { useEffect, useState } from 'react';
import { getData, postData } from '../../services/base.service';
import { Button, Grid, List, ListItem, ListItemText, IconButton, Typography, TextField, Select, MenuItem } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { MetaDataReferences } from '../../utils/consts';
import { set } from 'date-fns';

type MetadataManagementProps = {
  handleClose: Function;
  database: 'experts' | 'references';
}

function MetadataManagement({ handleClose, database = 'references' }: MetadataManagementProps) {
  const [newMetadata, setNewMetadata] = useState<string>('');
  const [selectedMetadataTable, setSelectedMetadataTable] = useState<string>(
    MetaDataReferences.find((metadata) => metadata.database === database)?.tables[0] ?? '' );
  const [metaDataList, setMetaDataList] = useState<string[]>([]);
  const [listChanged, setListChanged] = useState<boolean>(false);
  const [metadataTables, setMetadataTables] = useState<string[]>([]);

  useEffect(() => {
    //get the metadata table per database
    const metadata = MetaDataReferences.find((metadata) => metadata.database === database);
    setMetadataTables(metadata?.tables ?? []);
    
    // Fetch metadata from API
     loadData();
  }, [selectedMetadataTable]);

    const loadData = async () => {
      const data = await getData(`metadata/${selectedMetadataTable}`);
      setMetaDataList(data);
    }

  const handleAdd = () => {
    if (newMetadata.trim()) {
      //check if the metadata already exists, if so don't add it and alert the user that it already exists
        if (metaDataList.includes(newMetadata)) {
            alert('Please check for duplicates. The added Entry already exists');
            return;
        }
        else {
            setListChanged(true);
            setMetaDataList([...metaDataList, newMetadata]);
            setNewMetadata('');
        }
    }
  };

  const handleSave = () => {
    postData(`metadata/${selectedMetadataTable}`, {metaDataList}).then(() => {
      alert('Metadata saved successfully');
    });
  };

  const handleSelectionMetatadataChange = (event: any) => {
    setSelectedMetadataTable(event.target.value as string);
  };

  return (
    <>
    {metaDataList.length > 0 && (
      <Grid container justifyContent="center" alignItems="center" padding={3}>
        <Grid item xs={6}>
          <Typography variant="h5">Meta Data Management</Typography>
        </Grid>
        <Grid item xs={6} container justifyContent="flex-end">
          <IconButton onClick={() => handleClose()}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Typography>Add Options for the Metadata</Typography>
        </Grid>
      <Grid marginTop={2}>
        <Grid item xs={12}>
          <Typography>Select a Dataset</Typography>
          <Select value={selectedMetadataTable} onChange={handleSelectionMetatadataChange}>
            {metadataTables.map((metadata: string) => (
              <MenuItem key={metadata} value={metadata}>
                <ListItemText primary={metadata} />
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12}>
          <List>
            {metaDataList.map((metadata, index) => (
              <ListItem key={index}>
                <ListItemText primary={metadata} />
              </ListItem>
            ))}
            <ListItem>
              <TextField
                value={newMetadata}
                onChange={(e) => setNewMetadata(e.target.value)}
                placeholder="Add"
              />
              <IconButton onClick={handleAdd}>
                <AddIcon />
              </IconButton>
            </ListItem>
          </List>
            <Button disabled={!listChanged} variant="contained" color="primary" onClick={handleSave} startIcon={<SaveIcon />}>
              Save
            </Button>
        </Grid>
      </Grid>
      </Grid>
    )}
      </>
  );
}

export default MetadataManagement;