/** @jsxImportSource @emotion/react */
import { useMemo, useState } from "react";
import ExpertsFilters from "../components/Filters/ExpertsFilters";
import ExpertsTable from "../components/Tables/ExpertsTable";
import { Container, Backdrop, Fade, Box, Button, Card, CardContent, Grid, Fab, Dialog, DialogTitle, Typography, Menu, MenuItem, ListItemIcon } from '@mui/material';
import { ExpertDataArrayType, FiltersType, MenuItemProps } from "../types/dataTypes";
import { pulseAnimation } from '../styles/styles';
import { useTheme } from '@mui/material/styles';
import { Add, ArrowForward, FormatListBulleted, KeyboardArrowDown, KeyboardArrowUp, SwitchRight } from "@mui/icons-material";
import SortFeature from "../components/Shared/SortFeature";
import ScrollTop from "../components/Navigation/ScrollTop";
import AddExpert from "../components/Popups/AddExpert";
import UpdateExpert from "../components/Popups/UpdateExpert";
import MetadataManagement from "../components/Popups/MetadataManagement";
import CustomMenu from "../components/Shared/CustomMenue";

type ReferencesViewProps = {
  userRoles: string[];
};

function ExpertsView({ userRoles }: ReferencesViewProps) {
  const [data, setData] = useState<ExpertDataArrayType>([]);
  const [filteredData, setFilteredData] = useState<ExpertDataArrayType>([]);
  const [page, setPage] = useState(1);
  const [pageTotal, setPageTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: 'cvYear', direction: "desc" });
  const [isDrawerAdvancedFilterOpen, setIsDrawerDrawerAdvancedFilterOpen] = useState(false);
  const [isDrawerDetailsOpen, setIsDrawerDetailsOpen] = useState(false);
  const [isTableView, setIsTableView] = useState<boolean>(true);
  const [numCardsToDisplay, setNumCardsToDisplay] = useState(25);
  const [selectedExpert, setSelectedExpert] = useState(0);
  const [openAddDialog, setOpenAddDialoge] = useState(false);
  const [manageMetaDataDialogOpen, setManageMetaDataDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);



  const theme = useTheme();
  const accessToken = localStorage.getItem('accessToken') || '';

  // Initialize state for filters and available options
  const [filters, setFilters] = useState<FiltersType>({
    names: [],
    languages: [],
    regions: [],
    donors: [],
    keywords: [],
    fulltext: [],
    nationalities: [],
    ageRange: [],
  });

  const handledetailsUpdateDialogOpen = (expertID: number) => {
    setSelectedExpert(expertID);
    setIsDrawerDetailsOpen((prevState: any) => !prevState);
  };

  const handleManageMetaDataDialogOpen = () => {
    setManageMetaDataDialogOpen(true);
  };

  const handleManageMetaDataDialogClose = () => {
    setManageMetaDataDialogOpen(false);
  };

  const handleManageOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleManageClose = () => {
    setAnchorEl(null);
  };

  const menuItems: MenuItemProps[] = [
    {
      name: 'Add Expert',
      IconButton: Add,
      onClickFunction: () => setOpenAddDialoge(true),
      adminOnly: false,
    },
    {
      name: 'Manage Meta Data',
      IconButton: FormatListBulleted,
      onClickFunction: handleManageMetaDataDialogOpen,
      adminOnly: true,
    },
  ];

  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          transition: 'margin 0.3s ease',
          marginLeft: isDrawerAdvancedFilterOpen ? `20vw` : 0,
          marginRight: isDrawerDetailsOpen ? `40vw` : 0,
          overflow: 'auto',
        }}
      >
        <Card sx={{ margin: 1 }}>
          <CardContent>
            <ExpertsFilters data={data} setData={setData} setFilteredData={setFilteredData} setPageTotal={setPageTotal}
              page={page} setLoading={setLoading} filters={filters} setFilters={setFilters} sortConfig={sortConfig}
              isDrawerOpen={isDrawerAdvancedFilterOpen} setIsDrawerOpen={setIsDrawerDrawerAdvancedFilterOpen} />
            {/* <Grid item xs={12} md={1}>
                <Button size="small" variant="outlined" color="primary" onClick={() => { isTableView ? setIsTableView(false) : setIsTableView(true) }}
                  startIcon={<SwitchRight />}>
                  {isTableView ? 'Card View' : 'Table View'}
                </Button>
              </Grid> */}
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item>
                <Button variant="text" color="primary" onClick={() => setIsDrawerDrawerAdvancedFilterOpen((prevState: any) => !prevState)}
                  startIcon={<ArrowForward />}>
                  Advanced Filters
                </Button>
              </Grid>
              <Grid item>
              <CustomMenu
                    anchorEl={anchorEl}
                    handleClose={handleManageClose}
                    menuItems={menuItems}
                    handleManageOpen={handleManageOpen}
                    userRoles={userRoles}
                    />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div id="back-to-top-anchor" />
            <ScrollTop>
              <Fab color="primary" size="small" aria-label="scroll back to top">
                <KeyboardArrowUp />
              </Fab>
            </ScrollTop>
          </Grid>
          <Grid container spacing={1} justifyContent='space-between'>
            <Grid container spacing={0.5} justifyContent='center'>
              <ExpertsTable
                data={filteredData} page={page} setPage={setPage} pageTotal={pageTotal}
                sortConfig={sortConfig} setSortConfig={setSortConfig}
                handleOpenUpdateDialog={handledetailsUpdateDialogOpen}
              />
            </Grid>
          </Grid>
          {selectedExpert != 0 ? (
          <UpdateExpert
            setLoading={setLoading}
            accessToken={accessToken}
            expertID={selectedExpert}
            setFilters={setFilters}
            isDrawerOpen={isDrawerDetailsOpen}
            setIsDrawerDetailsOpen={handledetailsUpdateDialogOpen}
          />
        ) : null}
          <Backdrop open={loading} style={{ zIndex: 9999, color: '#fff' }}>
            <Fade in={loading}>
              <img src={theme.logo} alt="Company Logo" css={pulseAnimation} />
            </Fade>
          </Backdrop>
        </Grid>
      </Box>
      <Dialog
        open={openAddDialog}
        onClose={() => setOpenAddDialoge(false)}
        aria-labelledby="add-expert-dialog"
        fullWidth={true}
        maxWidth="lg"
      >
        <AddExpert setLoading={setLoading} accessToken={accessToken} expertID={0} setDetailsDrawerOpen={setOpenAddDialoge} 
        setFilters={setFilters}/>
      </Dialog>
      <Dialog open={manageMetaDataDialogOpen} onClose={handleManageMetaDataDialogClose} fullWidth maxWidth="sm">
         <MetadataManagement handleClose={handleManageMetaDataDialogClose} database="experts" />
      </Dialog>
    </Container>
  );
};

export default ExpertsView;