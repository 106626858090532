import { useState, useEffect } from "react";
import { getData, postData } from "../../services/base.service";
import { Button, Select, MenuItem, Grid, Typography, Divider, IconButton, InputLabel, FormControl } from "@mui/material";
import { Close, Download } from "@mui/icons-material";
import { VariantListType } from "../../types/dataTypes";

type PrintTemplatesProps = {
  project_number_list: string[];
  handleClose: Function;
}

const PrintTemplates = ({ project_number_list, handleClose }: PrintTemplatesProps) => {
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [selectedReferences, setSelectedReferences] = useState<VariantListType[]>()

  useEffect(() => {
    const fetchDataTemplates = async () => {
      const resultTemplates = await getData("templates");
      setTemplates(resultTemplates);
      setSelectedTemplate(resultTemplates[0]);
    };
    const fetchDataVariantList = async () => {
      const resultTemplates = await postData("references/variantlist", { project_numbers: project_number_list });
      setSelectedReferences(resultTemplates as VariantListType[]);
    };
    fetchDataTemplates();
    fetchDataVariantList();
  }, [project_number_list]);


  const printTemplate = async () => {
    if (selectedTemplate === "" || selectedTemplate === undefined) {
      alert("Please select a template");
      return;
    }
    if (!project_number_list || project_number_list.length === 0) {
      alert("Please select a project number");
      return;
    }

    const projectNo = project_number_list[0];
    const filename = `${projectNo}_${selectedTemplate}`;

    const selectedReferencesActive = selectedReferences?.filter((reference) => reference.selected === true);

    try {
      const blob = await postData(
        `templates/print/${selectedTemplate}`,
        { vairiant_list: selectedReferencesActive },
        "blob"
      );
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      if (link.parentNode) {
        link.parentNode.removeChild(link);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleSelectedTemplatesChange = (event: any) => {
    setSelectedTemplate(event.target.value);
  };

  const handleVariantSelection = (event: any) => {
    const { name, value } = event.target;
    if (!selectedReferences) return;

    const newSelectedReferences = [...selectedReferences];

    for (let item of newSelectedReferences) {
      if (item.ProjectNo === name) {
        item.selected = false;
      }
    }
    for (let item of newSelectedReferences) {
      if (item.ProjectNo === name && item.name === value) {
        item.selected = true;
      }
    }

    setSelectedReferences(newSelectedReferences);
  };

  return (
    <Grid container spacing={1} style={{ padding: 20 }}>
      <Grid item xs={12} container justifyContent="space-between" alignItems="center">
        <Typography variant="h5">Print Templates</Typography>
        <IconButton onClick={() => handleClose()}>
          <Close />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <Typography>Select a Reference Variant for each Project Number - The default is English (only one Variant per Reference)</Typography>
      </Grid>
      {selectedReferences && project_number_list.map((project_number) => (
        <Grid container spacing={2} key={project_number} style={{ margin: 10 }}>
          <Grid item xs={6}>
          <FormControl fullWidth>
          <InputLabel id={project_number}>{project_number}</InputLabel>
            <Select
              value={selectedReferences.find((ref: VariantListType) => ref.ProjectNo === project_number && ref.selected === true)?.name}
              onChange={handleVariantSelection}
              name={project_number}
              fullWidth
              label={project_number}
            >
              {selectedReferences.filter((reference) => reference.ProjectNo === project_number)
                .map((reference) => reference.name).map((name) => (
                  <MenuItem key={`${project_number}-${name}`} value={name}>
                    {name}
                  </MenuItem>
                ))}
            </Select>
            </FormControl>
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12}>
        <Divider style={{ margin: '20px 0' }} />
      </Grid>
      <Grid container alignItems="center" justifyContent="center" spacing={1}>
        <Grid item xs={12}>
          <Typography align="center" style={{ fontWeight: 'bold' }}>Select a Template to Print the Reference(s)</Typography>        </Grid>
        <Grid item xs={6}>
          <Select
            value={selectedTemplate}
            onChange={handleSelectedTemplatesChange}
            fullWidth
          >
            {Array.isArray(templates) &&
              templates.map((template, index) => (
                <MenuItem key={`${template}-${index}`} value={template}>
                  {template}
                </MenuItem>
              ))}
          </Select>
        </Grid>
        <Grid item xs={12}>
          <Button startIcon={<Download />} color="primary" onClick={printTemplate} fullWidth>
            Print
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PrintTemplates;
