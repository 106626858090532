import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Card,
  Paper,
  IconButton,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { PAGE_LIMIT } from "../../utils/consts";
import { fetchDocumentUrlAndOpenDialog } from "../../services/sharepoint.service";
import { ArrowForward, FileOpen} from '@mui/icons-material';

type ExpertsTableProps = {
  data: any[],
  page: number,
  setPage: Function,
  pageTotal: number,
  setSortConfig: Function,
  sortConfig: { key: string, direction: string }
  handleOpenUpdateDialog: Function,
}

function ExpertsTable({
  data,
  page,
  setPage,
  pageTotal,
  setSortConfig,
  sortConfig,
  handleOpenUpdateDialog,
}: ExpertsTableProps) {
  const accessToken = localStorage.getItem('accessToken') || '';

  const requestSort = (key: string) => {
    let direction: "asc" | "desc" = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key: key, direction });
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  return (
      <Card>  
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
            <TableRow>
            <TableCell sx={{ width: '10px', fontWeight: 'bold', position: 'sticky', top: 0, backgroundColor: '#fff', padding: '10px' }}>
              <TableSortLabel active={sortConfig.key === 'name'} direction={sortConfig.key === 'name' ? sortConfig.direction as "asc" | "desc" : "asc"} onClick={() => requestSort('name')}>
              Name </TableSortLabel> 
            </TableCell>
            <TableCell sx={{ width: '100px', fontWeight: 'bold', position: 'sticky', top: 0, backgroundColor: '#fff', padding: '10px' }}>
              <TableSortLabel active={sortConfig.key === 'keywords'} direction={sortConfig.key === 'keywords' ? sortConfig.direction as "asc" | "desc" : "asc"} onClick={() => requestSort('keywords')}>
              Experience </TableSortLabel> 
            </TableCell>
            <TableCell sx={{ width: '10px', fontWeight: 'bold', position: 'sticky', top: 0, backgroundColor: '#fff', padding: '10px' }}>
              <TableSortLabel active={sortConfig.key === 'Age'} direction={sortConfig.key === 'Age' ? sortConfig.direction as "asc" | "desc" : "asc"} onClick={() => requestSort('Age')}>
              Age </TableSortLabel> 
            </TableCell>
            <TableCell sx={{ width: '10px', fontWeight: 'bold', position: 'sticky', top: 0, backgroundColor: '#fff', padding: '10px' }}>
              <TableSortLabel active={sortConfig.key === 'nationality'} direction={sortConfig.key === 'nationality' ? sortConfig.direction as "asc" | "desc" : "asc"} onClick={() => requestSort('nationality')}>
              Nationality </TableSortLabel> 
            </TableCell>
            <TableCell sx={{ width: '10px', fontWeight: 'bold', position: 'sticky', top: 0, backgroundColor: '#fff', padding: '10px' }}>
              <TableSortLabel active={sortConfig.key === 'languages'} direction={sortConfig.key === 'languages' ? sortConfig.direction as "asc" | "desc" : "asc"} onClick={() => requestSort('languages')}>
              Languages </TableSortLabel> 
            </TableCell>
            <TableCell sx={{ width: '10px', fontWeight: 'bold', position: 'sticky', top: 0, backgroundColor: '#fff', padding: '10px' }}>
              <TableSortLabel active={sortConfig.key === 'regions'} direction={sortConfig.key === 'regions' ? sortConfig.direction as "asc" | "desc" : "asc"} onClick={() => requestSort('regions')}>
              Regions </TableSortLabel> 
            </TableCell>
            <TableCell sx={{ width: '10px', fontWeight: 'bold', position: 'sticky', top: 0, backgroundColor: '#fff', padding: '10px' }}>
              <TableSortLabel active={sortConfig.key === 'donors'} direction={sortConfig.key === 'donors' ? sortConfig.direction as "asc" | "desc" : "asc"} onClick={() => requestSort('donors')}>
              Donors </TableSortLabel> 
            </TableCell>
            <TableCell sx={{ width: '10px', fontWeight: 'bold', position: 'sticky', top: 0, backgroundColor: '#fff', padding: '10px' }}>
              <TableSortLabel active={sortConfig.key === 'email'} direction={sortConfig.key === 'email' ? sortConfig.direction as "asc" | "desc" : "asc"} onClick={() => requestSort('email')}>
              Email </TableSortLabel> 
            </TableCell>
            <TableCell sx={{ width: '10px', fontWeight: 'bold', position: 'sticky', top: 0, backgroundColor: '#fff', padding: '10px' }}>
              <TableSortLabel active={sortConfig.key === 'cvYear'} direction={sortConfig.key === 'cvYear' ? sortConfig.direction as "asc" | "desc" : "asc"} onClick={() => requestSort('cvYear')}>
              latest CV Year </TableSortLabel> 
            </TableCell>
            <TableCell sx={{ width: '10px', fontWeight: 'bold', position: 'sticky', top: 0, backgroundColor: '#fff', padding: '10px' }}>
              <TableSortLabel active={sortConfig.key === 'consent'} direction={sortConfig.key === 'consent' ? sortConfig.direction as "asc" | "desc" : "asc"} onClick={() => requestSort('consent')}>
              Consent </TableSortLabel> 
            </TableCell>
            <TableCell sx={{ width: '10px', fontWeight: 'bold', position: 'sticky', top: 0, backgroundColor: '#fff', padding: '10px' }}> View Latest CV </TableCell>
            <TableCell sx={{ width: '10px', fontWeight: 'bold', position: 'sticky', top: 0, backgroundColor: '#fff', padding: '10px' }}> View Details </TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item,index) => (
                  <TableRow
                  key={index}
                  sx={{
                    "&:nth-of-type(odd)": {
                      backgroundColor: "#f2f2f2",
                    },
                  }}
                >
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.keywords}</TableCell>
                  <TableCell>{item.Age}</TableCell>
                  <TableCell>{item.nationality}</TableCell>
                  <TableCell>{item.languages}</TableCell>
                  <TableCell>{item.regions}</TableCell>
                  <TableCell>{item.donors}</TableCell>
                  <TableCell>{item.email}</TableCell>
                  <TableCell>{item.cvYear}</TableCell>
                  <TableCell>{item.consent == true ? 'Yes' : 'No'}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => fetchDocumentUrlAndOpenDialog(item.cvlink, accessToken)}>
                        <FileOpen />
                    </IconButton>                   
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleOpenUpdateDialog(item.expertID)}>
                      <ArrowForward />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination count={Math.ceil(pageTotal / PAGE_LIMIT)} page={page} onChange={handleChangePage} />
        {/* <Dialog
          open={openUpdateDialog}
          onClose={handleCloseUpdateDialog}
          aria-labelledby="expert-details-dialog"
          fullWidth={true}
          maxWidth="lg"
        >
          <DialogTitle id="expert-details-dialog">
          <Typography variant="h5">Expert Details</Typography>
          </DialogTitle>
          {selectedExpert && <UpdateExpert setLoading={setLoading} expertID={selectedExpert} accessToken={accessToken} setOpenUpdateDialoge={setOpenUpdateDialoge}
          setFilters={setFilters} handleCloseDialoge = {handleCloseUpdateDialog}/>}
        </Dialog> */}
      </Card>
  );
}

export default ExpertsTable;
