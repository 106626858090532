import { MenuItem, Select, Button, Typography, Grid, Box } from "@mui/material";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

type SortFeatureType = {
    fieldList: string[];
    fieldIconList?: any;
    sortConfig: { key: string; direction: string };
    setSortConfig: Function;
}

function SortFeature({ fieldList, fieldIconList, sortConfig, setSortConfig }: SortFeatureType) {
    const handleSortChange = (field: string, direction: string) => {
        setSortConfig({ key: field, direction });
    };

    return (
        <>
        <Grid container direction="row" alignItems="center">
            <Grid item>
            <Select sx={{
                boxShadow: "none",
                ".MuiOutlinedInput-notchedOutline": { border: 0 },
                "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                {
                    border: 0,
                },
                "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                    border: 0,
                },
                '& .MuiSelect-select': {
                    paddingRight: 0,
                    paddingLeft: 0,
                    paddingTop: 0,
                    paddingBottom: 0,
                },
            }}

                value={sortConfig.key}
                onChange={(e) => handleSortChange(e.target.value, sortConfig.direction)}
            >
                {fieldList.map((field, index) => (
                    <MenuItem key={index} value={field}>
                        <Box display="flex" alignItems="center" padding={0}>
                            {fieldIconList && fieldIconList[index]} {field}
                        </Box>
                    </MenuItem>
                ))}
            </Select>
            </Grid>
            <Grid item>
            <Button
                onClick={() => handleSortChange(sortConfig.key, 'asc')}
                style={{
                    color: sortConfig.direction === 'asc' ? 'green' : 'grey',
                    fontWeight: sortConfig.direction === 'asc' ? 'bold' : 'normal'
                }}
                size="small"
            >
                <ArrowUpwardIcon />
            </Button>
            <Button
                onClick={() => handleSortChange(sortConfig.key, 'desc')}
                style={{
                    color: sortConfig.direction === 'desc' ? 'green' : 'grey',
                    fontWeight: sortConfig.direction === 'desc' ? 'bold' : 'normal'
                }}
                size="small"
            >
                <ArrowDownwardIcon />
            </Button>
            </Grid>
        </Grid>
        </>
    )
}

export default SortFeature;