import React from 'react';
import { Chip, TextField, IconButton, Switch } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

type AutocompleteComponentType = {
  options: any[];
  value: any;
  handleChange: Function;
  isMulti?: boolean;
  label: string;
  disabled?: boolean;
  error?: boolean;
  radioState?: boolean;
  setRadioState?: Function;
};

function AutocompleteComponent({ options, value, handleChange, isMulti = false, label, disabled, error,
  radioState = undefined, setRadioState = undefined }: AutocompleteComponentType) {
  // Ensure value is always an array if multiple is true
  const safeValue = isMulti ? (Array.isArray(value) ? value : []) : value;

  return (
    <div>
      <Autocomplete
        style={{ width: '100%' }}
        multiple={isMulti}
        options={options}
        value={safeValue}
        readOnly={disabled}
        onChange={(event, newValue) => {
          handleChange(event, newValue || (isMulti ? [] : ''));
        }}
        renderInput={(params) => (
          <TextField 
            {...params} 
            variant="outlined" 
            label={label} 
            error={error}
            InputLabelProps={{
              shrink: true,
              sx: {
                color: 'primary',
                "&.Mui-focused": {
                  color: 'primary'
                }
              }
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {params.InputProps.endAdornment}
                  {radioState  != undefined && setRadioState != undefined ? (
                    <IconButton
                      onClick={() => setRadioState(!radioState)}
                      size="small"
                    >
                      {radioState ? "AND" : "OR"}
                    </IconButton>
                  ) : null}
                </>
              ),
            }}
          />
        )}
        renderTags={isMulti ? (value, getTagProps) =>
          value.map((option, index) => (
            option !== '' ? <Chip variant="outlined" label={option} {...getTagProps({ index })} /> : null
          )) : undefined
        }
        clearOnEscape
      />
    </div>
  );
}

export default AutocompleteComponent;