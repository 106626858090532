import { List, ListItem, ListItemText, IconButton, AppBar, Toolbar, Typography, Button, Box, Select, Menu, MenuItem, ListItemIcon, useMediaQuery, useTheme, SwipeableDrawer, Grid } from '@mui/material';
import {  PeopleAlt as PeopleAltIcon, Book as BookIcon, Handshake, RocketLaunch, Checklist, Menu as MenuIcon } from '@mui/icons-material';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { useNavigate, useLocation } from 'react-router-dom';
import React, { useState, SetStateAction, Dispatch } from 'react';
import { UserType } from '../../types/dataTypes';
import UserMenu from './UserMenu';
import { useIsAuthenticated, useAccount, useMsal } from "@azure/msal-react";

type ToolbarNavigationProps = {
  user: UserType,
}

function ToolbarNavigation({ user }: ToolbarNavigationProps) {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {['Experts', 'References', 'Projects'].map((text, index) => (
          <ListItem button key={text} onClick={() => navigate(`/${text.toLowerCase()}`)}>
            <ListItemIcon>{index === 0 ? <PeopleAltIcon /> : index === 1 ? <ListAltIcon /> : <Checklist />}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      <AppBar position="fixed">
        <Toolbar>
          {isMobile ? (
            <>
            <Grid container justifyContent="space-between">
              <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
                <MenuIcon />
              </IconButton>
              <SwipeableDrawer
                anchor="left"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
              >
                {list()}
              </SwipeableDrawer>
              <Button onClick={() => navigate('/')}>
                <img src={theme.logo} alt="Logo" style={{ width: '70px', marginRight: '30px' }} />
              </Button>
              <UserMenu user={user} />
              </Grid>
            </>
          ) : (
            <>
              <Button onClick={() => navigate('/')}>
                <img src={theme.logo} alt="Logo" style={{ width: '70px', marginRight: '30px' }} />
              </Button>
              {isAuthenticated ? (
                <>
                  <Box
                    sx={{ borderBottom: location.pathname === '/experts' ? '2px solid white' : 'none', display: 'inline-flex', alignItems: 'center', marginRight: 2 }}
                  >
                    <Button onClick={() => navigate('/experts')} startIcon={<PeopleAltIcon style={{ fontSize: '30', color:'white' }} />}>
                      <Typography variant="body1" color="white">Experts</Typography>
                    </Button>
                  </Box>
                  <Box
                    sx={{ borderBottom: location.pathname === '/references' ? '2px solid white' : 'none', display: 'inline-flex', alignItems: 'center', marginRight: 2  }}
                  >
                    <Button onClick={() => navigate('/references')} startIcon={<ListAltIcon style={{ fontSize: '30', color:'white'  }} />}>
                      <Typography variant="body1" color="white">References</Typography>
                    </Button>
                  </Box>
                  <Box
                    sx={{ borderBottom: location.pathname === '/tenderpipeline' || location.pathname === '/ongoingprojects' ? '2px solid white' : 'none', marginRight: 2, 
                    display: 'inline-flex', alignItems: 'center' }}
                  >
                    {/* <Button onClick={handleMenuOpen} startIcon={<Checklist/>}  sx={{ color: 'white' }}>
                      <Typography variant="body1">Projects</Typography>
                    </Button>
                    <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={() => { navigate('/tenderpipeline'); handleMenuClose(); }}>
                      <ListItemIcon>
                        <Handshake />
                      </ListItemIcon>
                      <Typography variant="inherit">Tender Pipeline</Typography>
                    </MenuItem>
                    <MenuItem onClick={() => { navigate('/ongoingprojects'); handleMenuClose(); }}>
                      <ListItemIcon>
                        <RocketLaunch />
                      </ListItemIcon>
                      <Typography variant="inherit">Ongoing Projects</Typography>
                    </MenuItem>
                  </Menu> */}
                  </Box>
                </>
              ) : null}
              <Box sx={{ flexGrow: 1 }} />
              <UserMenu user={user} />
            </>
          )}
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  );
}

export default ToolbarNavigation;