import React, { useState } from 'react';
import CountUp from 'react-countup';
import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { OnEndArgs, OnEndCallback } from 'react-countup/build/types';
import {Refresh, Tag} from '@mui/icons-material';

function CountUpComponent({ resetFilters, end, duration = 1 }: {resetFilters: Function, end: number, duration?: number }) {
  const [start, setStart] = useState(0);

  const handleEnd: OnEndCallback = (args: OnEndArgs) => {
    setStart(end);
  };

  return (
    <Card sx={{marginTop:0}} >
      <CardContent>
        <Grid container spacing={1}>
          <Grid item xs={6} md={12}>
            <Button variant="text" color="primary" onClick={() => resetFilters()} startIcon={<Refresh />}>
              Reset Filter
            </Button>
          </Grid>
          <Grid item xs={6} md={12}>
          <Typography variant="h5">
            <Tag/>
            <CountUp start={start} end={end} duration={duration} onEnd={handleEnd} />
        </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default CountUpComponent;