import { Dayjs } from "dayjs";
import React from "react";

export type UserType = string | null;
export type AuthenticateType = (user: string | null) => void;
export type SetUserType = React.Dispatch<UserType>;

export type SingleExpertDataType = {
    Age: number, 
    consent: boolean,
    cvYear: string,
    cvlink: string,
    donors: string,
    email: string,
    exportID: number, 
    keywords: string,
    languages: string,
    name: string, 
    nationality: string,
    regions: string,
    [key: string]: any; 
};
export type ExpertDataArrayType = SingleExpertDataType[] | never[];
export type SetExpertDataArrayType = React.Dispatch<ExpertDataArrayType>;

export type PageType = number;
export type SetPageType = React.Dispatch<number>;

export type LoadingType = boolean;
export type SetLoadingType = React.Dispatch<boolean>;

export type FiltersType = { 
    [key: string]: any; 
    names: string[];
    languages: string[];
    regions: string[];
    donors: string[];
    keywords: any; // not sure what keywords type is
    fulltext: string[];
}

export type ReferencesTableType = {
    [key: string]: any;
    ProjNumTitle: string;
    ProjectNo: string;
    ProjectTitle: string ;
    Country: string;
    OriginOfFunding: string;
    Client_short: string;
    ProjectDescription_short: string;
    StartDate: Date | string;
    CompletionDate: Date | string;
    ProjectValueEuro: number;
    FieldOfService: string;
    INTEE: boolean;
    Homepage: boolean;   
    country_homepage: string;  
    references_version_count: number;
}

export type ReferencesFilterType = { 
    completionDate: Dayjs; 
    countries: string[];
    selectedCompany: string;
    projectValueEuro: number;
    fieldsOfServices: string[];
    refNumTitles: string[];
    fullTextProjectNumberList: string[];
    printSelectionList: string[];
}

export type ReferencesMultiFiltersType = {
    countries: string[],
    fieldsOfService: string[],
    refNumTitles: string[],
}

export type ReferenceDetailType = {
    ProjectID?: number;
    LangVerID?: number;
    ProjectNo: string;
    FocusOn?: string;
    ProjectTitle: string;
    ProjectTitle_short?: string;
    Country: string;
    Location?: string;
    Region?: string;
    short: string;
    FieldOfService: string;
    country_homepage?: string;
    OriginOfFunding: string;
    StartDay?: string;
    StartDate: Date | string;
    EndDay?: string;
    CompletionDate: Date | string;
    ProjectValue?: string;
    ProportionLegalEntity?: string;
    NoOfStaff: string;
    NoOfPersonMonth: string;
    Personnel: string;
    Personnel_EU_EOI: string;
    AssociatedFirms?: string;
    Consortium?: string;
    Lead: boolean | undefined;
    SeniorStaff: string;
    ProjectResults: string;
    ProjectBackground: string;
    ProjectObjectives: string;
    ProjectActivities?: string;
    ServicesProvided: string;
    ImplementationFeatures?: string;
    ProjectDescription_short?: string;
    extrashort_GTZ_PQ?: string;
    KeyWords?: string;
    Picture?: string;
    Category?: string;
    zuständig?: string;
    ClientContact: string;
    ProjectValueEuro: number | string;
    ProjectValueDollar: number | string;
    Folder?: string;
    NewRef?: boolean | undefined;
    INTEE: boolean | undefined;
    CoP?: boolean | undefined;
    variant_name?: string;
    variant_description?: string;
    ClientID?: number;
    Homepage?: boolean | undefined;
    Bemerkungen?: string;
    ConstructionServicesEUR?: number;
    ConstructionServicesDollar?: number;
    ProjectDescription_short_german?: string;
    address?: string;
    NoOfPersonMonthsByAssociatedFirms?: string;
    Textvariante1?: string;
    Textvariante2?: string;
    Textvariante3?: string;
    NoOfPersonMonthINT?: string;
    ProjectDescription_detailed?: string;
    extrashort_GTZ_PQ_german?: string;
}

// TypeScript array containing names of required fields for referece form
export const requiredFieldsReferenceForm: string[] = [
    "ProjectNo",
    "ProjectTitle",
    "Country",
    "short",
    "FieldOfService",
    "OriginOfFunding",
    "StartDate",
    "CompletionDate",
    "NoOfStaff",
    "NoOfPersonMonth",
    "Personnel",
    "Lead",
    "SeniorStaff",
    "ProjectBackground",
    "ProjectObjectives",
    "ServicesProvided",
    "ClientContact",
    "ProjectValueEuro",
    "ProjectValueDollar",
    "ProjectResults"
];


// TypeScript array containing names of required fields for expert form
export const requiredFieldsExpertsForm: string[] = [
    "firstName",
    "lastName",
    "email",
];

export type ReferenceAvailableOptionsType = {
    clients: string[],
    countries: string[],
    fieldsofServices: string[],
    countries_homepage: string[],
}

export type LangVerType = {
    ID: number;
    ProjectID: number;
    ProjectTitle: string;
    Country: string;
    Location: string;
    OriginOfFunding: string;
    NoOfStaff: string;
    NoOfPersonMonth: string;
    Personnel: string;
    Personnel_EU_EOI: string;
    SeniorStaff: string;
    ProjectDescription_detailed: string;
    ProjectResults: string;
    ProjectBackground: string;
    ProjectObjectives: string;
    ProjectActivities: string;
    ServicesProvided: string;
    ImplementationFeatures: string;
    ProjectDescription_short: string;
    extrashort_GTZ_PQ: string;
    LangID: number;
    TmStamp: number;
    Bemerkungen: string;
    Homepage: boolean;
    [key: string]: any;
}

export type VariantListType = {
    ProjectNo: string;
    name: string;
    variantID: number;
    selected: boolean;
}

export type TreeNode = {
    id: string;
    label: string;
    children: TreeNode[];
  };
  
export type MenuItemProps = {
    name: string;
    IconButton: React.ElementType;
    onClickFunction: () => void;
    adminOnly?: boolean;
  };