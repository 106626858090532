import {Client} from '@microsoft/microsoft-graph-client';


export async function fetchDocumentUrlAndOpenDialog(documentName: string, accessToken: string) {
  fetch(`https://graph.microsoft.com/v1.0/drives/b!hVTLLV_f4U-aa6ckJ_BdPubT0nRzceBGhPhyV9RqwU14iAYrgRAKRK8eeT6jl9R4/root:/${encodeURIComponent(documentName)}`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    })
    .then(response => response.json())
    .then((data) => {
      const documentUrl = data['@microsoft.graph.downloadUrl'];
      console.log('Document URL:', documentUrl);
      
      if (documentName.toLowerCase().endsWith('.pdf')) {
        // Fetch the PDF data as a Blob
        fetch(documentUrl)
          .then(response => response.blob())
          .then(blob => {
            // Create an object URL for the Blob
            const objectUrl = URL.createObjectURL(blob);
            
            // Open the object URL in a new tab
            window.open(objectUrl, '_blank');
          });
      } else {
        // Use Office Online viewer for Office files
        const officeViewerUrl = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(documentUrl)}`;
        console.log('Office Viewer URL:', officeViewerUrl);
        window.open(officeViewerUrl, '_blank');
      }
    });    
}

export async function uploadDocumentToSharePoint(file: any, accessToken: string, folderName: string = '' ) {

    const client = Client.init({
        authProvider: (done) => {
            done(null, accessToken); // first parameter takes an error if there is one
        },
    });
      try {
        const response = await client.api(`/drives/b!hVTLLV_f4U-aa6ckJ_BdPubT0nRzceBGhPhyV9RqwU14iAYrgRAKRK8eeT6jl9R4/root:/${folderName}${encodeURIComponent(file.name)}:/content`)
                                      .put(file); // fileContent is the binary content of the file
  
        console.log('File uploaded successfully', response);
    } catch (error) {
        console.error('Error uploading file to SharePoint', error);
    }
}

export async function deleteDocumentFromSharePoint(documentName: string, accessToken: string) {

    fetch(`https://graph.microsoft.com/v1.0/drives/b!hVTLLV_f4U-aa6ckJ_BdPubT0nRzceBGhPhyV9RqwU14iAYrgRAKRK8eeT6jl9R4/root:/${encodeURIComponent(documentName)}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        })
        .then(response => {
          if (response.ok) {
            console.log('Document deleted successfully');
          } else {
            console.error('Error deleting document');
          }
        }
    );
};

export async function fetchDocumentsFromSharePoint(accessToken: string, documentName: string): Promise<Blob> {  
  try {
    const response = 
    await fetch(`https://graph.microsoft.com/v1.0/drives/b!hVTLLV_f4U-aa6ckJ_BdPubT0nRzceBGhPhyV9RqwU14iAYrgRAKRK8eeT6jl9R4/root:/${encodeURIComponent(documentName)}`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    });

    const blob = await response.blob();
    console.log('Document fetched successfully', blob);

    return blob;
  } catch (error) {
    console.error('Error fetching document', error);
    throw error; // Re-throw the error so it can be caught and handled by the calling function
  }
}



