import React from 'react';
import { Menu, MenuItem, ListItemIcon, Typography, Button, Grid } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import { MenuItemProps } from '../../types/dataTypes';

type CustomMenuProps = {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  menuItems: MenuItemProps[];
  handleManageOpen: (event: React.MouseEvent<HTMLElement>) => void;
  userRoles: string[];
};

function CustomMenu({anchorEl, handleClose, menuItems, handleManageOpen, userRoles}:CustomMenuProps) {
  return (
    <Grid item xs={12} md={2}>
      <Button size="small" variant="outlined" color="primary" onClick={handleManageOpen} endIcon={<KeyboardArrowDown />}>
        Manage
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {menuItems.map((item, index) => (
          (!item.adminOnly || ['AdminINTFra', 'AdminINTEE'].includes(userRoles[0])) && (
            <MenuItem key={index} onClick={item.onClickFunction}>
              <ListItemIcon>
                <item.IconButton color="primary" />
              </ListItemIcon>
              <Typography variant="inherit">{item.name}</Typography>
            </MenuItem>
          )
        ))}
      </Menu>
    </Grid>
  );
};

export default CustomMenu;