import { useEffect, useState } from 'react';
import { TextField, Button, Checkbox, FormControlLabel, Grid, Tabs, Tab, Box, Typography, Divider, Stack, InputAdornment, Tooltip, IconButton, Link, Select, Menu, MenuItem } from '@mui/material';
import { postData } from '../../services/base.service';
import {requiredFieldsReferenceForm, ReferenceAvailableOptionsType, ReferenceDetailType } from '../../types/dataTypes';
import AutocompleteComponent from '../Shared/AutocompleteComponent';
import { Add, Apartment, AutoStories, Close, Groups, Home, Info, List, MultipleStop, Numbers, Person, Public, Save } from '@mui/icons-material';
import { Edit, Lock } from '@mui/icons-material';
import React from 'react';
import ReferenceVersion from '../Popups/ReferenceVersion';


type ReferenceFormProps = {
    setLoading: Function,
    availableOptions: ReferenceAvailableOptionsType,
    formData: ReferenceDetailType[],
    type: string,
    selectedOptions: ReferenceAvailableOptionsType,
    setSelectedOptions: Function
    setReload: Function
    handleClose: Function
}

function ReferenceForm({ setLoading, availableOptions, formData, type, selectedOptions, setSelectedOptions, setReload, handleClose }:
    ReferenceFormProps) {
    const [tabValue, setTabValue] = useState(0);
    const [isEditMode, setIsEditMode] = useState(type === 'add');
    const [saveFormData, setSaveFormData] = useState(false);
    const [currentFormData, setCurrentFormData] = useState<ReferenceDetailType>(formData[0]);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [isNewVariant, setIsNewVariant] = useState(false);

    useEffect(() => {
        setCurrentFormData(formData.length > 1 ? formData.find(x => x.variant_name === 'Englisch') || formData[0] : formData[0]);
      }, [formData]);

    const openDrawer = () => {
        setDrawerOpen(true);
    };

    // Function to close the drawer
    const closeDrawer = () => {
        setDrawerOpen(false);
    };

    const toggleEditMode = () => {
        ;
        setIsEditMode(!isEditMode);
        //notify the user that the form is in edit mode
        alert(isEditMode ? 'Form is now in view mode. No changes possible now.' :
            'Form is now in edit mode. You can now edit the form and dont forget to hit save in the end.');
    };

    const handleInputChange = (event: any) => {
        const { name, value } = event.target;
        setCurrentFormData({ ...currentFormData, [name]: value });
    };

    const handleCheckboxChange = (event: any) => {
        const { name, checked } = event.target;
        setCurrentFormData({ ...currentFormData, [name]: checked });
    };

    const handleTabChange = (event: any, newValue: any) => {
        setTabValue(newValue);
    };

    const handleClientChange = (event: any, newValue: any) => {
        setSelectedOptions((prevData: ReferenceAvailableOptionsType) => ({ ...prevData, clients: newValue }));
    };

    const handleCountryChange = (event: any, newValue: any) => {
        setSelectedOptions((prevData: ReferenceAvailableOptionsType) => ({ ...prevData, countries: newValue }));
    }

    const handleCountryHomepageChange = (event: any, newValue: any) => {
        setSelectedOptions((prevData: ReferenceAvailableOptionsType) => ({ ...prevData, countries_homepage: newValue }));
    }

    const handleFoSChange = (event: any, newValue: any) => {
        setSelectedOptions((prevData: ReferenceAvailableOptionsType) => ({ ...prevData, fieldsofServices: newValue }));
    }

    const handleSave = async () => {
        setLoading(true);
        //concat the selected options to the form data
        if (selectedOptions.countries.length > 0) currentFormData.Country = selectedOptions.countries.join(', ');
        if (selectedOptions.clients.length > 0) currentFormData.short = selectedOptions.clients.join(', ');
        if (selectedOptions.fieldsofServices.length > 0) currentFormData.FieldOfService = selectedOptions.fieldsofServices.join('; ');
        if (selectedOptions.countries_homepage.length > 0) currentFormData.country_homepage = selectedOptions.countries_homepage.join(', ');

        // check if required fields are filled
        if (!formvalidityCheck(currentFormData)) {
            alert('Please fill in all required fields. Check the red marked fields. Please also consider the Text Tabs.');
            setSaveFormData(true);
            setLoading(false);
            return;
        }

        //save the form data
        console.log(currentFormData);
        const api_type = isNewVariant ? 'newVariant' : type;
        const resp = await postData(`references/${api_type}`, { formData: currentFormData })
        alert(resp.message);
        if (resp.statusCode === 200) handleClose(true);
        setLoading(false);
        setReload(true);
    }

    // const handleDelete = () => {
    //     setLoading(true);
    //     postData('references/delete', { ProjectNo: projectNumber });
    //     setLoading(false);
    // }

    const handleVersionChange = (event: any) => {
        const { value } = event.target;
        setCurrentFormData(formData.find(x => x.variant_name == value) || formData[0]);
    }

    const formvalidityCheck = (details: ReferenceDetailType): boolean => {
        let requiredFieldList = requiredFieldsReferenceForm;
        //add a conditional check for field country_homepage if homepage is checked
        if (currentFormData.Homepage === true) {
            requiredFieldList.push('country_homepage');
        }
        if (currentFormData.Homepage === false && requiredFieldList.includes('country_homepage')) {
            //remove the field from the required fields list if it is not required
            requiredFieldList = requiredFieldList.filter((field: any)=> field !== 'country_homepage');
        }
        //check if all required fields are filled via a loop over the required fields and check if they are not undefined or empty
        //if they are, return false and log the error
        for (const field of requiredFieldList) {
            if (details[field as keyof ReferenceDetailType] === undefined || details[field as keyof ReferenceDetailType] === '') {
                console.error(`Validation error: ${field} is required.`);
                return false;
            }
        }
        return true;
    }

    return (
        <div>
            <Grid container spacing={2} sx={{
                borderBottom: 1,
                borderColor: 'divider',
                position: 'sticky',
                top: 0,
                background: "white",
                zIndex: 1100,
                boxShadow: 1
            }}>

<Grid container alignItems="center">
  <Grid item xs={6} md={6} style={{ display: 'flex', alignItems: 'center', padding: 10 }}>
    {type === 'add' ? (
      <Typography paddingLeft={3} variant="h5">Add a new Reference</Typography>
    ) : null}
  </Grid>
  <Grid item xs={6} md={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: 10 }}>
    {type === 'update' && (
      <Button
        variant="outlined"
        startIcon={isEditMode ? <Lock /> : <Edit />}
        onClick={toggleEditMode}
        color="primary"
        style={{ marginRight: 10 }}
      >
        {isEditMode ? 'Lock' : 'Edit'}
      </Button>
    )}
    {(type === 'update' && isEditMode) || type === 'add' ? (
      <Button
        variant="outlined"
        color="primary"
        startIcon={isEditMode ? <Save /> : <Save />}
        style={{ marginRight: 10 }}
        onClick={() => handleSave()}
      >
        {!isNewVariant ? 'Save' : 'Save Variant'}
      </Button>
    ) : null}
    {type === 'update' && (
      <Button
        variant="outlined"
        color="primary"
        startIcon={<MultipleStop />}
        onClick={() => openDrawer()}
        style={{ marginRight: 10 }}
      >
        Variants
      </Button>
    )}
    {type === 'add' ? (
      <IconButton onClick={() => handleClose()}>
        <Close />
      </IconButton>
    ) : null}
  </Grid>
</Grid>
                <Grid item xs={12}>
                    <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab label="Project" {...a11yProps(0)} />
                        <Tab label="Text Long" {...a11yProps(1)} />
                        <Tab label="Text Short" {...a11yProps(2)} />
                    </Tabs>
                </Grid>
            </Grid>
            <TabPanel value={tabValue} index={0}>
                <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} paddingTop={2}>
                    <Grid item md={12}>
                        <Stack direction="row" alignItems="center" gap={1}>
                            <AutoStories />
                            <Typography variant="h6">General</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField InputLabelProps={{ shrink: true }} label="Project Number" name="ProjectNo" value={currentFormData.ProjectNo || ''}
                            onChange={handleInputChange} required InputProps={{ readOnly: !isEditMode }} error={saveFormData && currentFormData.ProjectNo === ''}
                            disabled={isNewVariant} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField InputLabelProps={{ shrink: true }} style={{ width: '100%' }} label="Project Title" name="ProjectTitle"
                            value={currentFormData?.ProjectTitle || ''} onChange={handleInputChange} required multiline rows={3} InputProps={{ readOnly: !isEditMode }}
                            error={saveFormData && currentFormData.ProjectTitle === ''} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField InputLabelProps={{ shrink: true }} style={{ width: '100%' }} label="Project Title Short"
                            name="ProjectTitle_short" value={currentFormData?.ProjectTitle_short || ''} onChange={handleInputChange}
                            multiline rows={3} InputProps={{ readOnly: !isEditMode }} disabled={isNewVariant} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField InputLabelProps={{ shrink: true }} label="Responsible (Intials)" name="zuständig"
                            value={currentFormData?.zuständig || ''} onChange={handleInputChange} InputProps={{ readOnly: !isEditMode }} disabled={isNewVariant} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormControlLabel control={<Checkbox size='small' checked={currentFormData?.INTEE} indeterminate={currentFormData?.INTEE === undefined}
                            style={{ color: saveFormData && currentFormData.INTEE === undefined ? 'red' : 'inherit' }}
                            onChange={handleCheckboxChange} name="INTEE" />} label="INTEE Project" required disabled={!isEditMode || isNewVariant} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Divider style={{ color: 'lightgrey' }} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Stack direction="row" alignItems="center" gap={1}>
                            <Numbers />
                            <Typography variant="h6">Date and Project Values</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <TextField label="Start Date" value={currentFormData?.StartDate || ''} onChange={handleInputChange}
                            type="date" InputLabelProps={{ shrink: true }} InputProps={{ readOnly: !isEditMode }} name='StartDate'
                            required error={saveFormData && currentFormData.StartDate === ''} disabled={isNewVariant} />
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <TextField label="Completion Date" value={currentFormData?.CompletionDate || ''} onChange={handleInputChange}
                            type="date" InputLabelProps={{ shrink: true }} InputProps={{ readOnly: !isEditMode }} name='CompletionDate'
                            required error={saveFormData && currentFormData.CompletionDate === ''} disabled={isNewVariant} />
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <TextField
                            name="ProjectValueEuro"
                            InputLabelProps={{ shrink: true }}
                            value={currentFormData?.ProjectValueEuro || ''}
                            onChange={handleInputChange}
                            required
                            label="Project Value Euro"
                            type="number"
                            disabled={isNewVariant}
                            InputProps={{
                                readOnly: !isEditMode,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Tooltip title={
                                            <React.Fragment>
                                                Consider Exchange rate from
                                                <a href="https://www.oanda.com/currency-converter/de/?from=EUR&to=USD&amount=1" target="_blank" rel="noopener noreferrer">
                                                    www.oanda.com
                                                </a>
                                                and use the Projects Start Date as reference
                                            </React.Fragment>
                                        }>
                                            <IconButton>
                                                <Info />
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                ),
                                inputProps: {
                                    pattern: "\\d*", // only allow digits
                                },
                            }}
                            error={saveFormData && currentFormData.ProjectValueEuro === ''}
                        />
                    </Grid>
                    <Grid xs={6} item md={6}>
                        <TextField
                            name="ProjectValueDollar"
                            InputLabelProps={{ shrink: true }}
                            value={currentFormData?.ProjectValueDollar || ''}
                            onChange={handleInputChange}
                            required
                            label="Project Value Dollar"
                            type="number"
                            disabled={isNewVariant}
                            InputProps={{
                                readOnly: !isEditMode, endAdornment: (
                                    <InputAdornment position="end">
                                        <Tooltip title={
                                            <React.Fragment>
                                                Consider Exchange rate from
                                                <a href="https://www.oanda.com/currency-converter/de/?from=EUR&to=USD&amount=1" target="_blank" rel="noopener noreferrer">
                                                    www.oanda.com
                                                </a>
                                                and use the Projects Start Date as reference
                                            </React.Fragment>
                                        }>
                                            <IconButton>
                                                <Info />
                                            </IconButton>
                                        </Tooltip>
                                    </InputAdornment>
                                ),
                                inputProps: {
                                    pattern: "\\d*", // only allow digits
                                },
                            }}
                            error={saveFormData && currentFormData.ProjectValueDollar === ''}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Divider style={{ color: 'lightgrey' }} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Stack direction="row" alignItems="center" gap={1}>
                            <Person />
                            <Typography variant="h6">Client</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <AutocompleteComponent
                            options={availableOptions.clients}
                            value={selectedOptions.clients != undefined ?
                                (selectedOptions.clients[0] === null ?
                                    undefined : selectedOptions.clients) : []}
                            handleChange={handleClientChange}
                            isMulti={true}
                            label="Client Selection"
                            disabled={!isEditMode || isNewVariant}
                            error={saveFormData && selectedOptions.clients.length === 0}
                        />
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <TextField fullWidth InputLabelProps={{ shrink: true }} label="Client Contact" name="ClientContact"
                            value={currentFormData?.ClientContact || ''}
                            onChange={handleInputChange} required multiline rows={3} InputProps={{ readOnly: !isEditMode }}
                            error={saveFormData && currentFormData.ClientContact === ''} disabled={isNewVariant}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField InputLabelProps={{ shrink: true }} label="Origin Of Funding" name="OriginOfFunding"
                            value={currentFormData?.OriginOfFunding || ''} onChange={handleInputChange} required multiline rows={3} InputProps={{ readOnly: !isEditMode }}
                            error={saveFormData && currentFormData.OriginOfFunding === ''} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Divider style={{ color: 'lightgrey' }} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Stack direction="row" alignItems="center" gap={1}>
                            <Public />
                            <Typography variant="h6">Country</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <AutocompleteComponent
                            options={availableOptions.countries.length > 0 ? availableOptions.countries.map((country) => country) : []}
                            value={selectedOptions.countries.length > 0 ? selectedOptions.countries : []}
                            handleChange={handleCountryChange}
                            isMulti={true}
                            label="Countries"
                            disabled={!isEditMode || isNewVariant}
                            error={saveFormData && selectedOptions.countries.length === 0}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField InputLabelProps={{ shrink: true }} label="Location within the country" name="Location"
                            value={currentFormData?.Location || ''} onChange={handleInputChange} InputProps={{ readOnly: !isEditMode }} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Divider style={{ color: 'lightgrey' }} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Stack direction="row" alignItems="center" gap={1}>
                            <List />
                            <Typography variant="h6">Topic</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <AutocompleteComponent
                            options={availableOptions.fieldsofServices.length > 0 ? availableOptions.fieldsofServices.map((fos) => fos) : []}
                            value={selectedOptions.fieldsofServices.length > 0 ? selectedOptions.fieldsofServices : []}
                            handleChange={handleFoSChange}
                            isMulti={true}
                            label="Fields of Service"
                            disabled={!isEditMode}
                            error={saveFormData && selectedOptions.fieldsofServices.length === 0}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField fullWidth={true} InputLabelProps={{ shrink: true }} label="Key Words" name="KeyWords"
                            value={currentFormData?.KeyWords || ''} onChange={handleInputChange} multiline rows={3} InputProps={{ readOnly: !isEditMode }}
                            disabled={isNewVariant} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Divider style={{ color: 'lightgrey' }} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Stack direction="row" alignItems="center" gap={1}>
                            <Apartment />
                            <Typography variant="h6">Association</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <FormControlLabel control={<Checkbox size='small' checked={currentFormData?.Lead} indeterminate={currentFormData?.Lead === undefined}
                            style={{ color: saveFormData && currentFormData.Lead === undefined ? 'red' : 'inherit' }}
                            onChange={handleCheckboxChange} name="Lead" />} label="INT Lead" required disabled={!isEditMode || isNewVariant} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField fullWidth={true} InputLabelProps={{ shrink: true }} label="Associated Firms"
                            name="AssociatedFirms" value={currentFormData?.AssociatedFirms || ''} onChange={handleInputChange}
                            multiline rows={3} InputProps={{ readOnly: !isEditMode }} disabled={isNewVariant} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField fullWidth={true} InputLabelProps={{ shrink: true }} label="Proportion of INT Staff %" type="number"
                            name="ProportionLegalEntity" value={currentFormData?.ProportionLegalEntity || ''} onChange={handleInputChange}
                            multiline rows={1} InputProps={{ readOnly: !isEditMode }} disabled={isNewVariant} />
                    </Grid>               
                    <Grid item xs={12} md={12}>
                        <Divider style={{ color: 'lightgrey' }} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Stack direction="row" alignItems="center" gap={1}>
                            <Groups />
                            <Typography variant="h6">Personell</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField fullWidth={true} InputLabelProps={{ shrink: true }} label="Personnel (Profiles)"
                            name="Personnel" value={currentFormData?.Personnel || ''} onChange={handleInputChange} required multiline rows={6}
                            InputProps={{ readOnly: !isEditMode }} error={saveFormData && currentFormData.Personnel === ''} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField fullWidth={true} InputLabelProps={{ shrink: true }} label="Personnel (EU EOI)"
                            name="Personnel_EU_EOI" value={currentFormData?.Personnel_EU_EOI || ''} onChange={handleInputChange} multiline
                            rows={6} InputProps={{ readOnly: !isEditMode }} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField fullWidth={true} InputLabelProps={{ shrink: true }} label="Names and Position Senior Staff"
                            name="SeniorStaff" value={currentFormData?.SeniorStaff || ''} onChange={handleInputChange}
                            required multiline rows={6} InputProps={{ readOnly: !isEditMode }} error={saveFormData && currentFormData.SeniorStaff === ''} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField fullWidth={true} InputLabelProps={{ shrink: true }} label="No of Staff (international/national)"
                            name="NoOfStaff" value={currentFormData?.NoOfStaff || ''} onChange={handleInputChange} required multiline rows={3}
                            InputProps={{ readOnly: !isEditMode }} error={saveFormData && currentFormData.NoOfStaff === ''} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField fullWidth={true} InputLabelProps={{ shrink: true }} label="No of Person Month (international/national)"
                            name="NoOfPersonMonth" value={currentFormData?.NoOfPersonMonth || ''} onChange={handleInputChange} required
                            multiline rows={3} InputProps={{ readOnly: !isEditMode }} error={saveFormData && currentFormData.NoOfPersonMonth === ''} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField fullWidth={true} InputLabelProps={{ shrink: true }} label="No of Person Month by INT (international/national)"
                            name="NoOfPersonMonthINT" value={currentFormData?.NoOfPersonMonthINT || ''} onChange={handleInputChange}
                            InputProps={{ readOnly: !isEditMode }} disabled={isNewVariant} />
                        <TextField style={{ marginTop: 20 }} fullWidth={true} InputLabelProps={{ shrink: true }}
                            label="No of Person Month by Associated Firms (international/national)" name="NoOfPersonMonthsByAssociatedFirms"
                            value={currentFormData?.NoOfPersonMonthsByAssociatedFirms} onChange={handleInputChange}
                            multiline rows={3} InputProps={{ readOnly: !isEditMode }} disabled={isNewVariant} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Divider style={{ color: 'lightgrey' }} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Stack direction="row" alignItems="center" gap={1}>
                            <Add />
                            <Typography variant="h6">Additional</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <FormControlLabel control={<Checkbox size='small' checked={currentFormData?.CoP} indeterminate={currentFormData?.CoP === undefined}
                            onChange={handleCheckboxChange} name="CoP" disabled={!isEditMode || isNewVariant} />} label="CoP" />
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <TextField fullWidth={true} InputLabelProps={{ shrink: true }} label="Remarks"
                            name="Bemerkungen" value={currentFormData?.Bemerkungen || ''} onChange={handleInputChange} multiline rows={4} InputProps={{ readOnly: !isEditMode }} />

                    </Grid>
                    <Grid item xs={12} md={5}>
                        <TextField InputLabelProps={{ shrink: true }} label="Reference Folder Path"
                            name="Folder" value={currentFormData?.Folder || ''} onChange={handleInputChange} InputProps={{ readOnly: !isEditMode }}
                            disabled={isNewVariant} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Divider style={{ color: 'lightgrey' }} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Stack direction="row" alignItems="center" gap={1}>
                            <Home />
                            <Typography variant="h6">Homepage</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <FormControlLabel control={<Checkbox size='small' checked={currentFormData?.Homepage} indeterminate={currentFormData?.Homepage === undefined}
                            onChange={handleCheckboxChange} disabled={!isEditMode || isNewVariant} name="Homepage" />} label="Available on Homepage" />
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <AutocompleteComponent
                            options={availableOptions.countries.length > 0 ? availableOptions.countries.map((country) => country) : []}
                            value={selectedOptions.countries_homepage.length > 0 ? selectedOptions.countries_homepage : []}
                            handleChange={handleCountryHomepageChange}
                            isMulti={true}
                            label="Country Homepage"
                            disabled={!isEditMode || isNewVariant}
                            error={saveFormData && currentFormData.country_homepage === '' && currentFormData?.Homepage === true}
                        />
                    </Grid>
                </Grid>
                <ReferenceVersion open={drawerOpen} onClose={closeDrawer} formData={formData} setCurrentFormData={setCurrentFormData} currentFormData={currentFormData}
                    handleVersionChange={handleVersionChange} toggleEditMode={toggleEditMode} setIsNewVariant={setIsNewVariant} />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} paddingTop={3}>
                    <Grid item xs={12} md={12}>
                        <TextField label="Project Background" name="ProjectBackground" value={currentFormData?.ProjectBackground || ''}
                            onChange={handleInputChange} multiline rows={6} fullWidth InputProps={{ readOnly: !isEditMode }}
                            required error={saveFormData && currentFormData.ProjectBackground === ''} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <TextField label="Services Provided" name="ServicesProvided" value={currentFormData?.ServicesProvided || ''}
                            onChange={handleInputChange} multiline rows={6} fullWidth InputProps={{ readOnly: !isEditMode }}
                            required error={saveFormData && currentFormData.ServicesProvided === ''} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <TextField label="Project Objectives" name="ProjectObjectives" value={currentFormData?.ProjectObjectives || ''}
                            onChange={handleInputChange} multiline rows={6} fullWidth InputProps={{ readOnly: !isEditMode }}
                            required error={saveFormData && currentFormData.ProjectObjectives === ''} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <TextField label="Project Results" name="ProjectResults" value={currentFormData?.ProjectResults || ''}
                            onChange={handleInputChange} multiline rows={6} fullWidth InputProps={{ readOnly: !isEditMode }} 
                            required error={saveFormData && currentFormData.ProjectResults === ''}/>
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
                <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }} paddingTop={3}>
                    <Grid item xs={12} md={12}>
                        <TextField label="Project Description Short" name="ProjectDescription_short" InputLabelProps={{ shrink: true }}
                            value={currentFormData?.ProjectDescription_short || ''} onChange={handleInputChange} multiline rows={4} fullWidth
                            InputProps={{ readOnly: !isEditMode }} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <TextField label="Project Description Short German" name="ProjectDescription_short_german" InputLabelProps={{ shrink: true }}
                            value={currentFormData?.ProjectDescription_short_german || ''} onChange={handleInputChange} multiline rows={4} fullWidth
                            InputProps={{ readOnly: !isEditMode }} disabled={isNewVariant} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <TextField label="Extra Short GTZ PQ" name="extrashort_GTZ_PQ" value={currentFormData?.extrashort_GTZ_PQ || ''} InputLabelProps={{ shrink: true }}
                            onChange={handleInputChange} multiline rows={4} fullWidth InputProps={{ readOnly: !isEditMode }} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <TextField label="Extra Short GTZ German" name="extrashort_GTZ_PQ_german" value={currentFormData?.extrashort_GTZ_PQ_german || ''}
                            InputLabelProps={{ shrink: true }}
                            onChange={handleInputChange} multiline rows={4} fullWidth InputProps={{ readOnly: !isEditMode }} disabled={isNewVariant} />
                    </Grid>
                </Grid>
            </TabPanel>
        </div>
    );
}


function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default ReferenceForm;