import { useEffect, useState } from "react";
import { useNavigate, Route, Routes, Navigate } from "react-router-dom";
import { useMsal, useIsAuthenticated, useAccount } from "@azure/msal-react";
import ExpertsView from "./pages/ExpertsView";
import ReferencesView from "./pages/ReferencesView";
import LandingPage from './pages/LandingPage';
import ToolbarNavigation from "./components/Navigation/ToolbarNavigation";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { createContext, useContext } from 'react';
import { themeINTEE, themeINTFRA } from "./utils/theme";
import { ThemeProvider } from '@mui/material/styles';

//import ProjectsPipelineView from "./pages/ProjectsPipelineView";

const ThemeContext = createContext(themeINTEE); // default theme
export const useTheme = () => useContext(ThemeContext);

interface IdTokenClaims {
  roles: string[];
}

function App() {
  const [accessToken, setAccessToken] = useState("");
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [username, setUsername] = useState("");
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const [userRoles, setUserRoles] = useState<string[]>([]); // Add a state for user roles
  const [theme, setTheme] = useState(themeINTEE);

  useEffect(() => {
    if (isAuthenticated && account) {
      setUsername(account.username);

      // Define the scopes for the access token
      const scopes = ["User.Read", "Files.ReadWrite.All", 'Sites.ReadWrite.All', 'Sites.Manage.All'];

      instance.setActiveAccount(account);
      // Try to acquire the token silently
      instance
        .acquireTokenSilent({
          scopes: scopes,
          account: account,
        })
        .then((response) => {
          setAccessToken(response.accessToken);
          setUserRoles((response.idTokenClaims as IdTokenClaims).roles);
          console.log(userRoles)
          localStorage.setItem('accessToken', response.accessToken);

          //set the theme based on the user role
          if (userRoles.includes('INTEE')) {
            setTheme(themeINTEE);
          } else if (userRoles.includes('INTFra') || userRoles.includes('AdminINTFra' )) {
            setTheme(themeINTFRA);
          }
        })
        .catch((error) => {
          console.error("Failed to acquire token silently:", error);
          if (error instanceof InteractionRequiredAuthError) {
            instance
              .acquireTokenRedirect({
                scopes: scopes,
              })
              .then((response: any) => {
                if (response && response.accessToken) {
                  const accessToken = response.accessToken;
                  console.log("Access token:", accessToken);
                }
              })
              .catch((error) => {
                console.error("Failed to acquire token interactively:", error);
              });
          }
        });
    }
  }, [isAuthenticated, navigate, account, instance, accessToken]);

  return (
    <div className="App">
      <ThemeContext.Provider value={theme}>
        <ThemeProvider theme={theme}>
          <ToolbarNavigation user={account?.username || ""} />
          <Routes>
            <Route path="/" element={<LandingPage isAuthenticated={isAuthenticated} userRole={userRoles[0]} />} />
            <Route path="/references" element={isAuthenticated && (userRoles.includes('INTEE') || userRoles.includes('INTFra') 
              || userRoles.includes('AdminINTFra') || userRoles.includes('AdminINTEE')) ? <ReferencesView userRoles = {userRoles}/> : <Navigate to="/" />} />
            <Route path="/experts" element={isAuthenticated && userRoles.includes('INTEE') || userRoles.includes('AdminINTEE') ? <ExpertsView userRoles = {userRoles}/> : <Navigate to="/" />} />
            {/* <Route path="/tenderpipeline" element={isAuthenticated && userRoles.includes('INTEE') ? <ProjectsPipelineView accessToken={accessToken} /> : <Navigate to="/" />} />         */}
          </Routes>
        </ThemeProvider>
      </ThemeContext.Provider>
    </div>
  );
}

export default App;