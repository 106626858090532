export function formatCurrency(value: number) {
    if (value >= 1000000) {
        return `${(value / 1000000).toFixed(2)} Mio`;
    } else if (value >= 1000) {
        return `${Math.floor(value / 1000)} k`;
    } else {
        return value;
    }
}
//utility function to format date in the format dd/mm/yyyy
export function formatDate(date: string) {
    return new Date(date).toLocaleDateString('en-CA');
}

export function formatCurrencyWithValueAndUnit(value: number) : { value: number , unit: string } {
    if (value >= 1000000) {
        return { value: parseFloat((value / 1000000).toFixed(2)), unit: 'Mio' };
    } else if (value >= 1000) {
        return { value: Math.floor(value / 1000), unit: 'k' };
    } else {
        return { value, unit: '' };
    }
}