import React, { useState } from "react";
import {
    Card,
    CardContent,
    CardActions,
    Checkbox,
    Button,
    Box,
    Typography,
    Divider,
    Grid,
    IconButton,
    Tooltip,
    Link,
} from "@mui/material";
import { formatCurrency } from "../../utils/utilFunctions";
import { ReferencesTableType } from "../../types/dataTypes";
import { Handshake, Public, Euro, Layers, RocketLaunch, Verified, Apartment, Description, Web, WebAsset, ArrowForward, Print } from '@mui/icons-material';
import IconTextRow from "../Shared/IconTextRow";

type ReferenceCardProps = {
    item: ReferencesTableType;
    isSelected: boolean;
    onDetailsClick: (projectNo: string) => void;
    onSelectChange: (projectNo: string) => void;
    setDrawerDetailsOpen: Function;
};

function ReferenceCard({ item, isSelected, onDetailsClick, onSelectChange }: ReferenceCardProps) {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleExpandClick = () => {
        setIsExpanded(!isExpanded);
    };

    const text = item.ProjectDescription_short;
    const displayText = isExpanded ? text : (text != undefined ? `${text.substring(0, 200)}...` : '');
    const lastThreeChars = displayText.slice(-2);
    const textWithoutLastThreeChars = displayText.slice(0, -2);


    return (
        <Card>
            <CardContent>
                <Link
                    component="button"
                    onClick={() => onDetailsClick(item.ProjectNo)}
                    underline="none"
                >
                    <Typography variant="h6" color={'primary'} style={{ textAlign: 'left' }}>
                        {item.ProjectNo} - {item.ProjectTitle}
                    </Typography>
                </Link>
                <Divider style={{ color: 'lightgrey' }} />
                <Grid container justifyContent="space-evenly">

                    <Grid item xs={12} sm={2}>
                        <IconTextRow
                            title="Client"
                            IconComponent={Handshake}
                            displayText={item.Client_short}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <IconTextRow
                            title="Country"
                            IconComponent={
                                Public}
                            displayText={item.Country}
                            country={item.Country}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <IconTextRow
                            title="Project Value"
                            IconComponent={Euro}
                            displayText={formatCurrency(item.ProjectValueEuro)}
                        />
                    </Grid>

                    <Grid item xs={12} sm={2}>

                        <IconTextRow
                            title="Start Date"
                            IconComponent={RocketLaunch}
                            displayText={item.StartDate != undefined ? item.StartDate.toString() : ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <IconTextRow
                            title="Completion Date"
                            IconComponent={Verified}
                            displayText={item.CompletionDate != undefined ? item.CompletionDate.toString() : ''}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>

                        <IconTextRow
                            title="Company Name"
                            IconComponent={Apartment}
                            displayText={item.INTEE == true ? 'INT_EE' : 'INT_FRA'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>

                        <IconTextRow
                            title="Homepage"
                            IconComponent={WebAsset}
                            displayText={item.Homepage ? 'Yes' : 'No'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8}>

                        <IconTextRow
                            title="Field of Service"
                            IconComponent={Layers}
                            displayText={item.FieldOfService}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} paddingTop={2}>
                        <Typography variant="body1">
                            {textWithoutLastThreeChars}
                            <span onClick={handleExpandClick} style={{ color: 'green', cursor: 'pointer', fontWeight: 'bold' }}>
                                {lastThreeChars}
                            </span>
                        </Typography>
                    </Grid>

                </Grid>

            </CardContent>
            <Divider style={{ color: 'lightgrey' }} />
            <CardActions>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item xs={6} container alignItems="center" spacing={1}>
                        <Grid item>
                            <Checkbox
                                checked={isSelected}
                                onChange={() => onSelectChange(item.ProjectNo)}
                                icon={<Print color="disabled" />}
                                checkedIcon={<Print color="primary" />}
                            />
                        </Grid>
                        <Grid item>
                            <Typography variant="body1">Select to Print</Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} container justifyContent="flex-end">
                        <Button
                            startIcon={<ArrowForward />}
                            size="small"
                            variant="text"
                            color="primary"
                            onClick={() => onDetailsClick(item.ProjectNo)}
                            sx={{ whiteSpace: 'nowrap' }}
                        >
                            View Details
                        </Button>
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    );
};

export default ReferenceCard;
